import { isReviewNetwork } from "../utils/channelsConfig";
import { defaultReviewFilters } from "../utils/filters";
import { selectFilters } from "./common";
import fetch, { getHost, handleFetchError } from "./fetch";
import { fetchPropertyInfo } from "./locations";
import { fetchStatsClusters } from "./overview";

export const REQUEST_COMPETITORS = "REQUEST_COMPETITORS";
export const RECEIVE_COMPETITORS = "RECEIVE_COMPETITORS";
export const REQUEST_COMPETITORS_RATINGS = "REQUEST_COMPETITORS_RATINGS";
export const RECEIVE_COMPETITORS_RATINGS = "RECEIVE_COMPETITORS_RATINGS";
export const REQUEST_COMPETITORS_STATS = "REQUEST_COMPETITORS_STATS";
export const RECEIVE_COMPETITORS_STATS = "RECEIVE_COMPETITORS_STATS";
export const REQUEST_COMPETITORS_RANK = "REQUEST_COMPETITORS_RANK";
export const RECEIVE_COMPETITORS_RANK = "RECEIVE_COMPETITORS_RANK";

const reviewsUrl = () => `${getHost()}/reviews`;
const contentsDataUrl = () => `${reviewsUrl()}/contentsData`;
const guestRatingsUrl = () => `${reviewsUrl()}/guestRatings`;
const channelsRankings = () => `${reviewsUrl()}/channelsRanking`;

function requestCompetitorsRatings({ currentMonitorId, monitorIds, filters }) {
  return {
    type: REQUEST_COMPETITORS_RATINGS,
    currentMonitorId,
    monitorIds,
    filters,
  };
}

function receiveCompetitorsRatings({ currentMonitorId, monitorIds, filters, ratings }) {
  return {
    type: RECEIVE_COMPETITORS_RATINGS,
    currentMonitorId,
    monitorIds,
    filters,
    ratings,
  };
}

function requestCompetitorsStats({ currentMonitorId, monitorIds, filters }) {
  return {
    type: REQUEST_COMPETITORS_STATS,
    currentMonitorId,
    monitorIds,
    filters,
  };
}

function receiveCompetitorsStats({ currentMonitorId, monitorIds, filters, stats }) {
  return {
    type: RECEIVE_COMPETITORS_STATS,
    currentMonitorId,
    monitorIds,
    filters,
    stats,
  };
}

function requestCompetitorsRank({ currentMonitorId, monitorIds, filters }) {
  return {
    type: REQUEST_COMPETITORS_RANK,
    currentMonitorId,
    monitorIds,
    filters,
  };
}

function receiveCompetitorsRank({ currentMonitorId, monitorIds, filters, rankings }) {
  return {
    type: RECEIVE_COMPETITORS_RANK,
    currentMonitorId,
    monitorIds,
    filters,
    rankings,
  };
}

function fetchSingleCompetitorRatingsForNetworks({ monitorId, networks, filters }) {
  return fetch(guestRatingsUrl(), {
    method: "POST",
    body: JSON.stringify({
      filters: {
        monitor_ids: [monitorId],
        networks,
        to: filters.timeRange.endDate,
        from: filters.timeRange.startDate,
      },
    }),
  })
    .then((response) => response.json())
    .catch(handleFetchError);
}

function fetchSingleCompetitorRankForNetworks({ monitorId, networks, filters }) {
  return fetch(channelsRankings(), {
    method: "POST",
    body: JSON.stringify({
      filters: {
        monitor_ids: [monitorId],
        networks,
        to: filters.timeRange.endDate,
        from: filters.timeRange.startDate,
      },
    }),
  })
    .then((response) => response.json())
    .catch(handleFetchError);
}

function fetchCompetitorsRatingsForNetworks({ monitorIds, networks, filters }) {
  const promises = monitorIds.map((monitorId) =>
    fetchSingleCompetitorRatingsForNetworks({
      monitorId,
      networks,
      filters,
    }),
  );

  return Promise.all(promises).then((competitorRatings) =>
    competitorRatings.reduce((aggregated, ratings, index) => {
      let ratingsByNetwork = ratings;
      if (networks.length === 1) {
        const { _timestamps: timestamps, ...groups } = ratings;
        ratingsByNetwork = { networks: { [networks[0]]: groups }, _timestamps: timestamps };
      }
      return {
        ...aggregated,
        [monitorIds[index]]: ratingsByNetwork,
      };
    }, {}),
  );
}

function fetchCompetitorsRankForNetworks({ monitorIds, networks, filters }) {
  const promises = monitorIds.map((monitorId) =>
    fetchSingleCompetitorRankForNetworks({
      monitorId,
      networks,
      filters,
    }),
  );

  return Promise.all(promises).then((competitorRank) =>
    competitorRank.reduce(
      (aggregated, ratings, index) => ({
        ...aggregated,
        [monitorIds[index]]: ratings,
      }),
      {},
    ),
  );
}

function fetchCompetitorsRatingsWithNetworks({
  currentMonitorId,
  networks = ["tripadvisor"],
  monitorIds,
  filters,
  dispatch,
}) {
  const filteredNetworks = networks.filter((network) => isReviewNetwork(network));
  const promise = fetchCompetitorsRatingsForNetworks({
    monitorIds,
    networks: filteredNetworks,
    filters,
  });

  return promise.then((competitorsRatings) =>
    dispatch(
      receiveCompetitorsRatings({
        currentMonitorId,
        monitorIds,
        filters,
        ratings: competitorsRatings,
      }),
    ),
  );
}

function fetchCompetitorsRankWithNetworks({
  currentMonitorId,
  monitorIds,
  filters,
  dispatch,
  networks = ["tripadvisor"],
}) {
  const filteredNetworks = [...networks];
  const promise = fetchCompetitorsRankForNetworks({
    monitorIds,
    networks: filteredNetworks,
    filters,
  });

  return promise.then((competitorsRank) =>
    dispatch(
      receiveCompetitorsRank({
        currentMonitorId,
        monitorIds,
        filters,
        rankings: competitorsRank,
      }),
    ),
  );
}

export function fetchCompetitorsRatings({
  currentMonitorId,
  monitorIds,
  filters,
  dispatch,
  parameters = {},
}) {
  dispatch(requestCompetitorsRatings({ currentMonitorId, monitorIds, filters }));
  if (parameters.networks) {
    return fetchCompetitorsRatingsWithNetworks({
      currentMonitorId,
      monitorIds,
      filters,
      dispatch,
      networks: parameters.networks,
    });
  }
  return dispatch(fetchPropertyInfo(currentMonitorId)).then((response) => {
    const { channels } = response.property.property;
    const networks = Object.keys(channels).filter((network) => network !== "website");
    return fetchCompetitorsRatingsWithNetworks({
      currentMonitorId,
      monitorIds,
      filters,
      dispatch,
      networks,
    });
  });
}

export function fetchCompetitorsRank({ currentMonitorId, monitorIds, filters, dispatch }) {
  dispatch(requestCompetitorsRank({ currentMonitorId, monitorIds, filters }));
  return fetchCompetitorsRankWithNetworks({
    currentMonitorId,
    monitorIds,
    filters,
    dispatch,
  });
}

function fetchSingleCompetitorStats({ monitorId, filters }) {
  return fetch(contentsDataUrl(), {
    method: "POST",
    body: JSON.stringify({
      filters: {
        orderBy: "date",
        monitor_ids: [monitorId],
        to: filters.timeRange.endDate,
        from: filters.timeRange.startDate,
        compute: ["sentimentSerie", "trends"],
        branches: defaultReviewFilters.branches,
      },
    }),
  })
    .then((response) => response.json())
    .catch(handleFetchError);
}

function fetchCompetitorsStats({ currentMonitorId, monitorIds, filters, dispatch, clusters }) {
  dispatch(requestCompetitorsStats({ currentMonitorId, monitorIds, filters }));
  const promises = monitorIds.map((monitorId) =>
    fetchSingleCompetitorStats({
      monitorId,
      filters,
    }),
  );
  const promises2 = monitorIds.map((monitorId) =>
    fetchStatsClusters({
      filters: {
        ...filters,
        monitorId,
      },
      dispatch,
      clusters,
    }),
  );
  Promise.all(promises2).then((results) =>
    Promise.all(promises)
      .then((competitorsStats) =>
        competitorsStats.reduce(
          (aggregated, stats, index) => ({
            ...aggregated,
            [monitorIds[index]]: {
              ...results[index].stats,
              ...stats,
            },
          }),
          {},
        ),
      )
      .then((competitorsStats) =>
        dispatch(
          receiveCompetitorsStats({
            currentMonitorId,
            monitorIds,
            filters,
            stats: competitorsStats,
          }),
        ),
      ),
  );
}

function fetchCompetitorsOverview({ filters, dispatch, getState }) {
  dispatch(selectFilters(filters));
  dispatch(requestCompetitorsRatings({ currentMonitorId: filters.monitorId, filters }));
  return dispatch(fetchPropertyInfo(filters.monitorId))
    .then(({ property }) => {
      const {
        configuration: { reviewClusters },
      } = getState();
      const monitorIds = Object.keys(property.competitors || {}).concat(filters.monitorId);
      return Promise.all([
        fetchCompetitorsRatings({
          currentMonitorId: filters.monitorId,
          monitorIds,
          filters,
          dispatch,
        }),
        fetchCompetitorsStats({
          currentMonitorId: filters.monitorId,
          monitorIds,
          filters,
          dispatch,
          clusters: Object.keys(reviewClusters),
        }),
        fetchCompetitorsRank({
          currentMonitorId: filters.monitorId,
          monitorIds,
          filters,
          dispatch,
        }),
        property, // return property always in last
      ]);
    })
    .catch(handleFetchError);
}

function shouldFetchCompetitorsOverview(currentMonitorId, getState) {
  const {
    selectedFilters,
    competitorsById,
    competitorsStatsById,
    competitorsRatingsById,
    competitorsRanksById,
  } = getState();
  const competitors = competitorsById[selectedFilters.key];
  const stats = competitorsStatsById[selectedFilters.key];
  const ratings = competitorsRatingsById[selectedFilters.key];
  const rankings = competitorsRanksById[selectedFilters.key];

  if (!competitors || !stats || !ratings || !rankings) {
    return true;
  }
  if (competitors.isFetching || stats.isFetching || ratings.isFetching || rankings.isFetching) {
    return false;
  }

  return (
    competitors.didInvalidate ||
    stats.didInvalidate ||
    ratings.didInvalidate ||
    rankings.didInvalidate
  );
}

export function dispatchCompetitorsOverview({ filters }) {
  return (dispatch, getState) => {
    if (shouldFetchCompetitorsOverview(filters.monitorId, getState)) {
      return fetchCompetitorsOverview({ filters, dispatch, getState });
    }

    return Promise.resolve();
  };
}
