import isNaN from "lodash/isNaN";
import { stringify } from "query-string";
import store from "store";

import log, { LOG_LEVEL } from "../utils/logger";
import filterNulls from "../utils/object";
import fetch, { getHost } from "./fetch";
import { updateLocation as updateInternalLocation } from "./locations";
import { setItem, pendingRequestKey } from "./storage";

export const PROPERTY_UPDATE_LOADING = "PROPERTY_UPDATE_LOADING";
export const PROPERTY_UPDATE_SUCCESSFUL = "PROPERTY_UPDATE_SUCCESSFUL";
export const PROPERTY_UPDATE_FAILED = "PROPERTY_UPDATE_FAILED";
export const RECEIVE_PROPERTY = "RECEIVE_PROPERTY";
export const RECEIVE_COMPETITOR_IDS = "RECEIVE_COMPETITOR_IDS";
export const REQUEST_PLACE_IDS = "REQUEST_PLACE_IDS";
export const RECEIVE_PLACE_IDS = "RECEIVE_PLACE_IDS";
export const REQUEST_AUTOCOMPLETE_PLACE_IDS = "REQUEST_AUTOCOMPLETE_PLACE_IDS";
export const RECEIVE_AUTOCOMPLETE_PLACE_IDS = "RECEIVE_AUTOCOMPLETE_PLACE_IDS";
export const REQUEST_PLACE_DETAILS = "REQUEST_PLACE_DETAILS";
export const FETCH_PLACE_DETAILS = "FETCH_PLACE_DETAILS";
export const RECEIVE_PLACE_DETAILS = "RECEIVE_PLACE_DETAILS";

const placeDetailsUrl = () => "/api/place/details";
const placeTextSearchUrl = () => "/api/place/textsearch";
const placeAutocompleteUrl = () => "/api/place/autocomplete";

const demotokenUrl = () => `${getHost()}/demotokens`;
const reviewsUrl = () => `${getHost()}/reviews`;
const propertyDiscoverUrl = () => `${reviewsUrl()}/propertyDiscover`;
const propertyDiscoverStatusUrl = (id) => `${reviewsUrl()}/propertyDiscoverQueue?id=${id}`;
const propertyUrl = () => `${reviewsUrl()}/property`;
const competitorUrl = () => `${reviewsUrl()}/competitor`;

export function fetchUpdateLocation(propertyId, placeId, locationId, customerId) {
  return (dispatch) =>
    dispatch(
      updateInternalLocation(
        {
          property_id: propertyId,
          place_id: placeId,
        },
        locationId,
        customerId,
      ),
    );
}

function receiveProperty(property) {
  return {
    type: RECEIVE_PROPERTY,
    property,
  };
}

function propertyUpdated(result) {
  return {
    type: PROPERTY_UPDATE_SUCCESSFUL,
    result,
  };
}

function propertyUpdateFailed(errors = []) {
  return {
    type: PROPERTY_UPDATE_FAILED,
    errors,
  };
}

function postProperty(data) {
  return fetch(propertyUrl(), {
    method: "POST",
    body: JSON.stringify({
      // default values if they will be not supplied by /propertyDiscover
      type: data.entity_type || "venue",
      category: "restaurant",
      ...filterNulls(data),
    }),
  });
}

export function postDemoToken(data) {
  return fetch(demotokenUrl(), {
    method: "POST",
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

function fetchPropertyPut(data) {
  const id = isNaN(+data.id) ? data.id : +data.id;
  return fetch(propertyUrl(), {
    method: "PUT",
    body: JSON.stringify({
      ...filterNulls(data),
      id,
    }),
  });
}

export function addProperty(data, placeId, locationId, customerId) {
  return (dispatch) =>
    postProperty(data)
      .then((response) => response.json())
      .then((json) => dispatch(receiveProperty(json)))
      .then(({ property: { id: propertyId } }) =>
        dispatch(
          updateInternalLocation(
            { property_id: propertyId, place_id: placeId },
            locationId,
            customerId,
          ),
        ),
      );
}

export function createProperty(data) {
  return (dispatch) =>
    postProperty(data)
      .then((response) => response.json())
      .then((json) => dispatch(receiveProperty(json)));
}

export function putProperty(data) {
  return (dispatch) => {
    dispatch({ type: PROPERTY_UPDATE_LOADING });
    return fetchPropertyPut(data)
      .then((response) => response.json())
      .then((json) => dispatch(propertyUpdated(json)))
      .catch((err) => dispatch(propertyUpdateFailed(err.messageData.errors)));
  };
}

function receivePropertyDiscoverId(index, propertyId, locationId) {
  const key = pendingRequestKey(index, locationId);
  const { properties: pendingProperties = {} } = store.get(key) || {};
  return setItem(key, { time: Date.now(), properties: [...pendingProperties, { id: propertyId }] });
}

function processRequest(response) {
  const location = response.headers.get("Location");
  return location.split("id=")[1];
}

function fetchPropertyDiscover(channels, index, dispatch, locationId, placeId) {
  return fetch(propertyDiscoverUrl(), {
    method: "POST",
    body: JSON.stringify({
      channels: {
        ...channels,
      },
      placeId: placeId || "",
    }),
  })
    .then((response) => processRequest(response))
    .then((propertyId) => dispatch(receivePropertyDiscoverId(index, propertyId, locationId)));
}

export function fetchPropertyDiscoverStatus(id) {
  return fetch(propertyDiscoverStatusUrl(id)).then((response) => response.json());
}

function addCompetitorById(competitorId, propertyId) {
  return fetch(competitorUrl(), {
    method: "POST",
    body: JSON.stringify({
      id: propertyId,
      competitor_id: competitorId,
    }),
  });
}

export function addCompetitorsByIds(competitorIds, propertyId) {
  const promises = competitorIds.map((id) =>
    addCompetitorById(id, propertyId).then((response) => response.json()),
  );

  return Promise.all(promises);
}

export function addCompetitorProperty(data, locationId) {
  return (dispatch) =>
    postProperty(data)
      .then((response) => response.json())
      .then((json) => dispatch(receiveProperty(json)))
      .then(({ property: { id } }) => addCompetitorById(id, locationId));
}

export function addCompetitorsByUrls(channels, locationId) {
  return (dispatch) => fetchPropertyDiscover(channels, "competitors", dispatch, locationId);
}

export function addCompetitorsByData(properties, propertyId) {
  const promises = properties.map(({ requestId: id, requestUrl: url, ...property }) =>
    postProperty(property)
      .then((response) => response.json())
      .catch((response) => ({ ...response.messageData, url, id })),
  );
  return Promise.all(promises).then((savedProperties) => {
    const competitorIds = savedProperties.filter((id) => !id.errors).map(({ id }) => id);
    if (competitorIds.length) {
      return addCompetitorsByIds(competitorIds, propertyId);
    }
    return savedProperties;
  });
}

function requestPlaces(venueName) {
  return {
    type: REQUEST_PLACE_IDS,
    venueName,
  };
}

function requestAutocompletePlaces(venueName) {
  return {
    type: REQUEST_AUTOCOMPLETE_PLACE_IDS,
    venueName,
  };
}

function receiveAutocompletePlaces(data) {
  return {
    type: RECEIVE_AUTOCOMPLETE_PLACE_IDS,
    places: data,
  };
}

function receivePlaces(data) {
  return {
    type: RECEIVE_PLACE_IDS,
    places: data,
  };
}

export function receivePlaceDetails(data) {
  return (dispatch) =>
    dispatch({
      type: RECEIVE_PLACE_DETAILS,
      payload: {
        result: data,
        status: "OK",
      },
    });
}

function fetchPlaces(venueName, lang) {
  const params = stringify({
    query: venueName,
    fields: "formatted_address,geometry,name,place_id,photo,icon",
    language: lang,
  });
  return fetch(`${placeTextSearchUrl()}?${params}`, {
    method: "GET",
    credentials: "omit",
  })
    .then((response) => response.json())
    .then((data) =>
      data.results.map(
        (placeId) => ({
          name: placeId.name,
          icon: placeId.icon,
          location: {
            address: placeId.formatted_address,
            geometry: placeId.geometry.location,
          },
          placeId: placeId.place_id,
          photoReference: placeId.photos ? placeId.photos[0].photo_reference : "",
        }),
        [],
      ),
    )
    .catch((e) => log("PlaceID API error", e, LOG_LEVEL.ERROR));
}

function fetchPlacesAutocomplete(input, options) {
  const params = stringify({
    input,
    types: "(regions)",
    fields: "description,place_id",
    ...options,
  });

  return fetch(`${placeAutocompleteUrl()}?${params}`, {
    credentials: "omit",
  })
    .then((res) => res.json())
    .then((data) =>
      data.predictions.map((place) => ({
        name: place.description,
        placeId: place.place_id,
      })),
    )
    .catch((e) => log("PlaceID Autocomplete error", e, LOG_LEVEL.ERROR));
}

export function dispatchSearchPlaceId(venueName) {
  return (dispatch, getState) => {
    const lang = getState().i18nState.lang || "en";
    dispatch(requestPlaces(venueName));
    return fetchPlaces(venueName, lang).then((data) => dispatch(receivePlaces(data)));
  };
}

export function placesAutocomplete(input, searchParams) {
  return (dispatch, getState) => {
    const lang = getState().i18nState.lang || "en";
    dispatch(requestAutocompletePlaces(input));
    return fetchPlacesAutocomplete(input, { language: lang, ...searchParams }).then((data) =>
      dispatch(receiveAutocompletePlaces(data)),
    );
  };
}

function requestPlaceDetails(placeId, params) {
  return {
    type: REQUEST_PLACE_DETAILS,
    placeId,
    params,
  };
}

function fetchPlaceDetails(params) {
  return {
    type: FETCH_PLACE_DETAILS,
    payload: fetch(`${placeDetailsUrl()}?${params}`, {
      method: "GET",
      credentials: "omit",
    }).then((res) => res.json()),
  };
}

export function fetchPlaceDetailsIfNeeded(placeId, params = {}) {
  return (dispatch, getState) => {
    const {
      placesDetails: { details },
      i18nState: { lang },
    } = getState();
    if (details[placeId]) {
      return Promise.resolve({ result: details[placeId] });
    }
    const query = stringify({
      placeid: placeId,
      language: lang,
      fields:
        params.fields ||
        "address_components,formatted_address,international_phone_number,opening_hours,website,geometry,name,place_id,photo,rating,user_ratings_total,url,reviews",
      ...params,
    });

    dispatch(requestPlaceDetails(placeId, query));
    return dispatch(fetchPlaceDetails(query)).then((data) => data.action.payload);
  };
}
