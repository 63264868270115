import { captureMessage } from "@sentry/react";
import { stringify } from "query-string";

import { getCookie } from "hooks/useCookieWatcher";

import { parseLinkHeader } from "../utils/formatting";
import { stringifyFilterParams } from "../utils/string";
import { receiveCampaign } from "./campaigns";
import fetch, { FetchError, getHost, getV3Host, handle401Redirection } from "./fetch";
import { receiveCampaignLinkage, receiveLocation, requestLocationCampaign } from "./locations";
import { receivePagination } from "./pagination";

export const REQUEST_CUSTOMER_BSP = "REQUEST_CUSTOMER_BSP";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const REQUEST_CUSTOMER = "REQUEST_CUSTOMER";
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const REQUEST_CUSTOMER_ACCOUNTS = "REQUEST_CUSTOMER_ACCOUNTS";
export const REQUEST_CUSTOMER_LOCATIONS = "REQUEST_CUSTOMER_LOCATIONS";
export const RECEIVE_CUSTOMER_LOCATIONS = "RECEIVE_CUSTOMER_LOCATIONS";
export const REQUEST_CUSTOMER_CAMPAIGNS = "REQUEST_CUSTOMER_CAMPAIGNS";
export const RECEIVE_CUSTOMER_CAMPAIGNS = "RECEIVE_CUSTOMER_CAMPAIGNS";
export const REQUEST_CUSTOMER_PRODUCTS = "REQUEST_CUSTOMER_PRODUCTS";
export const RECEIVE_CUSTOMER_PRODUCTS = "RECEIVE_CUSTOMER_PRODUCTS";

const csrfToken = getCookie("XSRF-TOKEN");
const listingsFetch = (url, options) =>
  fetch(url, {
    ...options,
    ...{
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        origin: window.location.origin,
        "X-XSRF-TOKEN": csrfToken,
      },
    },
  });

const customerUrl = (customerId) => `${getHost()}/customers/${customerId}`;
const meUrl = () => `${getV3Host()}/me`;
const customerLocationUrl = (customerId) => `${customerUrl(customerId)}/locations`;
const customerProductsUrl = (customerId) => `${customerUrl(customerId)}/products`;
const locationUrl = (locationId) => `${getHost()}/locations/${locationId}`;
const locationUrlV3 = (locationId) => `${getV3Host()}/locations/${locationId}`;

function requestCustomer(customerId) {
  return {
    type: REQUEST_CUSTOMER,
    customerId,
  };
}

function receiveCustomer(customerId, customer) {
  return {
    type: RECEIVE_CUSTOMER,
    customerId,
    customer,
    receivedAt: Date.now(),
  };
}

function requestCustomerLocations(customerId) {
  return {
    type: REQUEST_CUSTOMER_LOCATIONS,
    customerId,
  };
}

function receiveCustomerLocations(customerId, customerLocations) {
  return {
    type: RECEIVE_CUSTOMER_LOCATIONS,
    customerId,
    customerLocations,
    receivedAt: Date.now(),
  };
}

function requestCustomerCampaigns(customerId) {
  return {
    type: REQUEST_CUSTOMER_CAMPAIGNS,
    customerId,
  };
}

function receiveCustomerCampaigns(customerId, campaigns) {
  return {
    type: RECEIVE_CUSTOMER_CAMPAIGNS,
    customerId,
    campaigns,
    receivedAt: Date.now(),
  };
}

export function requestCustomerProducts(customerId) {
  return {
    type: REQUEST_CUSTOMER_PRODUCTS,
    customerId,
  };
}

export function receiveCustomerProducts(products) {
  return {
    type: RECEIVE_CUSTOMER_PRODUCTS,
    products,
  };
}

export function fetchCustomerLocations(customerId, params = {}) {
  return (dispatch) => {
    dispatch(requestCustomerLocations(customerId));
    return fetch(`${customerLocationUrl(customerId)}?${stringifyFilterParams(params)}`)
      .then((response) => response.json())
      .then((locations) => {
        dispatch(receiveCustomerLocations(customerId, locations));
      });
  };
}

export function fetchCustomerAccounts(customerId, params = { name: "" }) {
  return (dispatch) =>
    dispatch({
      type: REQUEST_CUSTOMER_ACCOUNTS,
      meta: { customerId },
      payload: fetch(`${customerUrl(customerId)}/accounts?${stringifyFilterParams(params)}`).then(
        (res) => res.json(),
      ),
    });
}

function fetchLocation(customerId, locationId) {
  return (dispatch) =>
    fetch(locationUrl(locationId))
      .then((response) => response.json())
      .then((json) => {
        dispatch(receiveCustomerLocations(customerId, [json]));
        dispatch(receiveLocation(json));
      })
      .catch((err) => {
        handle401Redirection(err.status);
        return Promise.reject(err);
      });
}

export function fetchLocationV3(customerId, locationId) {
  return (dispatch) =>
    listingsFetch(locationUrlV3(locationId))
      .then((response) => {
        return response.json().then((jsonData) => {
          try {
            const data = jsonData?.data;

            const newLocation = {
              id: data.id,
              name: data.name,
              phone: data.phone,
              timezone: data.timezone,
              external_reference: data.external_reference,
              comment: data.comment,
              comment_admin: data.comment_admin,
              account: data?.account,
              address: data?.address?.address_line,
              address2: data?.address?.address_line_extra,
              postalcode: data?.address?.postal_code,
              city: data?.address?.city,
              country: data?.address?.country,
              property_id: data?.advanced_settings?.property_id,
              listing_id: data?.advanced_settings?.listing_id,
              place_id: data?.advanced_settings?.place_id,
            };

            dispatch(receiveCustomerLocations(customerId, [newLocation]));
            dispatch(receiveLocation(newLocation));
          } catch (err) {
            captureMessage("Fetch Location v3 spec is not correct", {
              extra: { errorReason: err.message, json: jsonData },
            });
            throw new FetchError(response, jsonData);
          }
        });
      })
      .catch((err) => {
        handle401Redirection(err.status);
        return Promise.reject(err);
      });
}

function shouldFetchLocation(state, locationId) {
  if (!locationId) {
    return false;
  }
  return !state.locationsById[locationId];
}

export function fetchLocationIfNeeded(customerId, locationId) {
  return (dispatch, getState) => {
    if (shouldFetchLocation(getState(), locationId)) {
      return dispatch(fetchLocation(customerId, locationId));
    }
    return null;
  };
}

export function fetchLocationIfNeededV3(customerId, locationId) {
  return (dispatch, getState) => {
    if (shouldFetchLocation(getState(), locationId)) {
      return dispatch(fetchLocationV3(customerId, locationId));
    }
    return null;
  };
}

export function fetchCustomer(customerId) {
  return (dispatch) => {
    dispatch(requestCustomer(customerId));
    return fetch(customerUrl(customerId))
      .then((response) => response.json())
      .then((json) => dispatch(receiveCustomer(customerId, json)));
  };
}

export function fetchMe(customerId) {
  return (dispatch) => {
    dispatch(requestCustomer(customerId));
    return fetch(meUrl())
      .then((response) => response.json())
      .then((json) => dispatch(receiveCustomer(customerId, json)));
  };
}

export function fetchCustomerCampaigns(
  customerId,
  url = `${customerUrl(customerId)}/campaigns`,
  filters = {},
) {
  return (dispatch) => {
    dispatch(requestCustomerCampaigns(customerId));
    const query = Object.keys(filters).reduce(
      (str, key) => `${str}filter[${key}]=${filters[key]}&`,
      url.indexOf("?") !== -1 ? "&" : "?",
    );
    return fetch(`${url}${query}`)
      .then((response) => {
        dispatch(
          receivePagination("customerCampaigns", parseLinkHeader(response.headers.get("Link"))),
        );
        return response.json();
      })
      .then((campaigns) => {
        dispatch(
          receiveCustomerCampaigns(
            customerId,
            campaigns.map((campaign) => campaign.id),
          ),
        );
        campaigns.forEach((campaign) => dispatch(receiveCampaign(campaign)));
        return campaigns;
      });
  };
}

export function fetchLocationCampaignsLinkage(customerId, filters = {}) {
  return (dispatch) => {
    dispatch(requestLocationCampaign(customerId));
    const url = `${customerUrl(customerId)}/location-campaigns`;
    const query = Object.keys(filters).reduce(
      (str, key) => `${str}filter[${key}]=${filters[key]}&`,
      url.indexOf("?") !== -1 ? "&" : "?",
    );
    return fetch(`${url}${query}`)
      .then((response) => response.json())
      .then((data) => data.forEach((linkage) => dispatch(receiveCampaignLinkage(linkage))));
  };
}

export function fetchCustomerProducts(customerId) {
  return (dispatch) => {
    dispatch(requestCustomerProducts(customerId));

    return fetch(customerProductsUrl(customerId))
      .then((response) => response.json())
      .then((data) => dispatch(receiveCustomerProducts(data)));
  };
}

function shouldFetchCustomer(state, customerId) {
  const customer = state.customersById[customerId];
  if (!customer) {
    return true;
  }
  if (customer.isFetching) {
    return false;
  }

  return customer.didInvalidate;
}

export function fetchCustomerIfNeeded(customerId) {
  return (dispatch, getState) => {
    if (shouldFetchCustomer(getState(), customerId)) {
      return dispatch(fetchCustomer(customerId));
    }

    return null;
  };
}

export function fetchMeIfNeeded(customerId) {
  return (dispatch, getState) => {
    if (shouldFetchCustomer(getState(), customerId)) {
      return dispatch(fetchMe(customerId));
    }

    return null;
  };
}

export function updateCustomer(customerId, data) {
  return (dispatch) =>
    fetch(customerUrl(customerId), {
      method: "PATCH",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveCustomer(customerId, json)));
}

export function fetchBSPs(customerId, name) {
  return (dispatch) =>
    dispatch({
      type: REQUEST_CUSTOMER_BSP,
      payload: fetch(`${getHost()}/customers/${customerId}/bsp?${stringify({ name })}`).then(
        (res) => res.json(),
      ),
    });
}
