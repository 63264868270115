import { ActionType } from "redux-promise-middleware";

import {
  FETCH_PLACE_DETAILS,
  RECEIVE_AUTOCOMPLETE_PLACE_IDS,
  RECEIVE_COMPETITOR_IDS,
  RECEIVE_PLACE_DETAILS,
  RECEIVE_PLACE_IDS,
  RECEIVE_PROPERTY,
  REQUEST_AUTOCOMPLETE_PLACE_IDS,
  REQUEST_PLACE_IDS,
} from "../actions";

export function competitorIds(state = [], { type, competitorIds: id }) {
  switch (type) {
    case RECEIVE_COMPETITOR_IDS:
      return id;
    default:
      return state;
  }
}

export function propertyReducer(state = {}, { type, property }) {
  switch (type) {
    case RECEIVE_PROPERTY:
      return property;
    default:
      return state;
  }
}

function placesReducer(
  state = {
    isFetching: false,
    results: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_AUTOCOMPLETE_PLACE_IDS:
      return {
        ...state,
        autoComplete: {
          isFetching: true,
        },
      };
    case REQUEST_PLACE_IDS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_AUTOCOMPLETE_PLACE_IDS:
      return {
        ...state,
        autoComplete: {
          isFetching: false,
          results: action.places,
        },
      };
    case RECEIVE_PLACE_IDS:
      return {
        ...state,
        isFetching: false,
        results: action.places,
      };
    default:
      return state;
  }
}

export function places(state = { results: [] }, action) {
  switch (action.type) {
    case REQUEST_PLACE_IDS:
    case RECEIVE_PLACE_IDS:
    case REQUEST_AUTOCOMPLETE_PLACE_IDS:
    case RECEIVE_AUTOCOMPLETE_PLACE_IDS:
      return {
        ...placesReducer(state, action),
      };
    default:
      return state;
  }
}

function placeDetailsReject(state, action) {
  return {
    ...state,
    isRejected: true,
    isLoading: false,
    error: action.payload,
  };
}

export function placesDetails(
  state = {
    isLoading: false,
    isRejected: false,
    details: {},
  },
  action,
) {
  switch (action.type) {
    case `${FETCH_PLACE_DETAILS}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_PLACE_DETAILS:
    case `${FETCH_PLACE_DETAILS}_${ActionType.Fulfilled}`:
      if (action.payload.status === "INVALID_REQUEST") {
        return placeDetailsReject(state, action);
      }
      // other status then OK return empty data
      if (action.payload.status !== "OK") {
        return {
          ...state,
          isLoading: false,
          isRejected: false,
          error: null,
          status: action.payload.status,
        };
      }
      return {
        ...state,
        isLoading: false,
        isRejected: false,
        error: null,
        details: {
          ...state.details,
          [action.payload.result.place_id]: action.payload.result,
        },
      };
    case `${FETCH_PLACE_DETAILS}_${ActionType.Rejected}`:
      return placeDetailsReject(state, action);
    default:
      return state;
  }
}
