import castArray from "lodash/castArray";
import round from "lodash/round";

import { defaultReviewFilters } from "../utils/filters";
import fetch, { getHost, handleFetchError } from "./fetch";
import { fetchPropertyInfo } from "./locations";
import { requestGenericStats, receiveGenericStats } from "./overview";

/* global enqueueResourceRequest */
export const SELECT_REVIEW_FILTERS = "SELECT_REVIEW_FILTERS";
export const REMOVE_REVIEW_FILTERS = "REMOVE_REVIEW_FILTERS";
export const REQUEST_REVIEW_DETAILS = "REQUEST_REVIEW_DETAILS";
export const RECEIVE_REVIEW_DETAILS = "RECEIVE_REVIEW_DETAILS";
export const REQUEST_REVIEWS = "REQUEST_REVIEWS";
export const RECEIVE_REVIEWS = "RECEIVE_REVIEWS";
export const SEND_NOTIFY_SEMANTIC = "REQUEST_NOTIFY_SEMANTIC";
export const RECEIVE_NOTIFY_SEMANTIC = "RECEIVE_NOTIFY_SEMANTIC";

const reviewsUrl = () => `${getHost()}/reviews`;
const contentsDataUrl = () => `${reviewsUrl()}/contentsData`;
const contentsUrl = () => `${reviewsUrl()}/contents`;
const notifySemanticUrl = () => `${reviewsUrl()}/notifySemanticError`;

export function selectReviewFilters(filters) {
  return {
    type: SELECT_REVIEW_FILTERS,
    filters,
  };
}

export function removeReviewFilter(filter) {
  return {
    type: REMOVE_REVIEW_FILTERS,
    filter,
  };
}

export function sendNotifySemantic(message, contentsId) {
  return {
    type: SEND_NOTIFY_SEMANTIC,
    message,
    contentsId,
  };
}

export function receiveNotifySemantic(response) {
  return {
    type: RECEIVE_NOTIFY_SEMANTIC,
    response,
  };
}

function requestStatsDetails(filters) {
  return requestGenericStats(filters, REQUEST_REVIEW_DETAILS);
}

function receiveStatsDetails(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_REVIEW_DETAILS);
}

function requestReviews(filters) {
  return requestGenericStats(filters, REQUEST_REVIEWS);
}

function receiveReviews(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_REVIEWS);
}

export function fetchContents(json) {
  const { contentsID } = json;
  if (!contentsID || contentsID.length === 0) {
    return Promise.resolve({ contents: [] });
  }
  return fetch(contentsUrl(), {
    method: "POST",
    body: JSON.stringify({
      filters: {
        contentsID,
      },
    }),
  }).then((response) => response.json());
}

function shouldFetchStats(stats) {
  if (!stats) {
    return true;
  }
  if (stats.isFetching) {
    return false;
  }
  return stats.didInvalidate;
}

function fetchStats({ filters, url = contentsDataUrl(), parameters = {} }) {
  const {
    monitorId,
    language,
    country,
    timeRange,
    sentiment,
    network,
    response,
    orderBy,
    unanswered,
    dishes,
    arguments: args,
    keywords,
  } = filters;
  const formattedFilters = {
    orderBy: defaultReviewFilters.orderBy,
    to: timeRange.endDate,
    from: timeRange.startDate,
    monitor_ids: [monitorId],
    keyword_strategy: defaultReviewFilters.keyword_strategy,
    branches: defaultReviewFilters.branches,
  };
  if (network) {
    formattedFilters.networks = castArray(network);
  }
  if (orderBy) {
    formattedFilters.orderBy = orderBy;
  }
  if (response) {
    formattedFilters.responses = castArray(response).map((bool) => bool === "true");
  }
  if (sentiment) {
    formattedFilters.sentiment_ranges = castArray(sentiment).map((s) =>
      s === "null" ? null : [s - 20, round(s)],
    );
  }
  if (country) {
    formattedFilters.countries = castArray(country);
  }
  if (language) {
    formattedFilters.languages = castArray(language);
  }
  if (args) {
    formattedFilters.arguments = args;
  }
  if (unanswered) {
    formattedFilters.unanswered = unanswered;
  }
  if (dishes) {
    formattedFilters.dishes = dishes;
  }
  if (keywords) {
    formattedFilters.keywords = keywords;
  }
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      filters: {
        ...formattedFilters,
        ...parameters,
      },
    }),
  }).then((resJson) => resJson.json());
}

function fetchStatsDetails({ filters, parameters, dispatch }) {
  dispatch(requestStatsDetails(filters));
  return fetchStats({
    filters,
    parameters: {
      ...parameters,
      sentiment_ranges_stream: {
        null: null,
        20: [0, 19.99],
        40: [20, 39.99],
        60: [40, 59.99],
        80: [60, 79.99],
        100: [80, 100],
      },
      compute: [
        "contentsID",
        "sentiment",
        "trends",
        "count",
        "sentimentSerie",
        "contentPositivitySeries",
        "streamCounters",
      ],
    },
  });
}

function getReview({ filters, parameters, dispatch }) {
  return fetchStatsDetails({ filters, parameters, dispatch }).then((json) =>
    fetchContents(json).then((response) => ({
      ...json,
      ...response,
    })),
  );
}

export function fetchMoreContents(contentsID, filters) {
  return (dispatch) => {
    dispatch(requestStatsDetails(filters));
    return fetchContents({ contentsID }).then((response) =>
      dispatch(receiveStatsDetails(filters, response)),
    );
  };
}

function fetchStatsReview({ getState, dispatch, filters, ...params }) {
  const { statsReviewByFilters, selectedReviewFilters } = getState();
  const { key, ...storedFilters } = selectedReviewFilters;
  if (shouldFetchStats(statsReviewByFilters[key])) {
    // Removed network from parameters if a filter is set.
    // Since parameters have precedent over filters,
    // we need to remove these when a user only wants to see certain networks
    const newParams = { ...params };
    if (Array.isArray(filters.network) && filters.network.length !== 0) {
      delete newParams.parameters?.networks;
    }
    return enqueueResourceRequest((finished) => {
      getReview({ ...newParams, dispatch, filters: storedFilters })
        .then((data) => dispatch(receiveStatsDetails(storedFilters, data)))
        .then(finished)
        .catch(handleFetchError);
    });
  }

  return null;
}

export function dispatchStatsReview({ filters, parameters = {} }) {
  return (dispatch, getState) => {
    dispatch(selectReviewFilters(filters));
    const { monitorId } = getState().selectedReviewFilters;
    const promise = dispatch(fetchPropertyInfo(monitorId));
    fetchStatsReview({
      filters,
      parameters,
      dispatch,
      getState,
    });
    return promise;
  };
}

export function dispatchRemoveFiltereview({ filters, parameters = {} }) {
  return (dispatch, getState) => {
    dispatch(removeReviewFilter(filters));
    return fetchStatsReview({
      filters,
      parameters,
      dispatch,
      getState,
    });
  };
}

export function fetchReviews({ filters, parameters }) {
  return fetchStats({
    filters,
    parameters: {
      ...parameters,
      compute: ["contentsID"],
      branches: defaultReviewFilters.branches,
    },
  });
}

export function dispatchReviews({ parameters }) {
  return (dispatch, getState) => {
    const { ...filters } = getState().selectedReviewFilters;
    dispatch(requestReviews({ ...filters, overwrite: true }));
    return fetchReviews({ filters, parameters })
      .then((json) =>
        fetchContents(json).then((response) => ({
          ...json,
          ...response,
        })),
      )
      .then((response) => dispatch(receiveReviews(filters, response)))
      .catch(handleFetchError);
  };
}

export function notifySemanticError(message, contentsId) {
  return (dispatch) => {
    dispatch(sendNotifySemantic(message, contentsId));
    return fetch(notifySemanticUrl(), {
      method: "POST",
      body: JSON.stringify({
        message,
        filters: { contentsID: [contentsId] },
      }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveNotifySemantic(json)));
  };
}
