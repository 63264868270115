import omitBy from "lodash/omitBy";
import { stringify } from "query-string";

import fetch, { getHost } from "./fetch";

export const FETCH_PARTNER = "FETCH_PARTNER";
export const FETCH_PARTNERS_BY_ID = "FETCH_PARTNERS_BY_ID";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const FETCH_ALL_PARTNERS = "FETCH_ALL_PARTNERS";
export const FETCH_PARTNER_USERS = "FETCH_PARTNER_USERS";
export const FETCH_PARTNER_USER = "FETCH_PARTNER_USER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_USER = "CREATE_PARTNER_USER";
export const FETCH_PARTNER_SSO_TOKEN = "FETCH_PARTNER_SSO_TOKEN";
export const GENERATE_PARTNER_SSO_TOKEN = "GENERATE_PARTNER_SSO_TOKEN";
export const DEACTIVATE_PARTNER_SSO_TOKEN = "DEACTIVATE_PARTNER_SSO_TOKEN";
export const MAKE_PARTNER_USER_PRIMARY = "MAKE_PARTNER_USER_PRIMARY";
export const SEND_RESET_PASSWORD_PARTNER_USER = "SEND_RESET_PASSWORD_PARTNER_USER";
export const DELETE_PARTNER_USER = "DELETE_PARTNER_USER";
export const UPDATE_PARTNER_USER = "UPDATE_PARTNER_USER";
export const REMOVE_DELETE_PARTNER_ERROR = "REMOVE_DELETE_PARTNER_ERROR";
export const FETCH_PARTNERS_SENDER_EMAILS = "FETCH_PARTNERS_SENDER_EMAILS";
export const REMOVE_CURRENT_PARTNER = "REMOVE_CURRENT_PARTNER";

export function fetchPartner(partnerId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNER,
      payload: fetch(`${getHost()}/resellers/${partnerId}`).then((res) => res.json()),
    });
}

export function removeCurrentPartner() {
  return (dispatch) => dispatch({ type: REMOVE_CURRENT_PARTNER });
}

export function updatePartner({ partnerId, data }) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_PARTNER,
      payload: fetch(`${getHost()}/resellers/${partnerId}`, {
        method: "PATCH",
        body: JSON.stringify(data, (key, value) => (value === "" ? null : value)),
      }).then((res) => res.json()),
    });
}

export function fetchAllPartners(params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };

  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  return (dispatch) =>
    dispatch({
      type: FETCH_ALL_PARTNERS,
      payload: fetch(`${getHost()}/resellers?${urlParams}`).then((res) => res.json()),
    });
}

export function deletePartner(partnerId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_PARTNER,
      payload: fetch(`${getHost()}/resellers/${partnerId}`, {
        method: "DELETE",
        id: partnerId,
      }).then((res) => res.json()),
    });
}

export function createPartner(data) {
  return (dispatch) =>
    dispatch({
      type: CREATE_PARTNER,
      payload: fetch(`${getHost()}/resellers`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function fetchPartnerSSOToken(partnerId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNER_SSO_TOKEN,
      payload: fetch(`${getHost()}/resellers/${partnerId}/sso-token`).then((res) => res.json()),
    });
}

export function generatePartnerSSOToken(partnerId) {
  return (dispatch) =>
    dispatch({
      type: GENERATE_PARTNER_SSO_TOKEN,
      payload: fetch(`${getHost()}/resellers/${partnerId}/sso-token`, {
        method: "POST",
      }).then((res) => res.json()),
    });
}

export function deactivatePartnerSSOToken(partnerId) {
  return (dispatch) =>
    dispatch({
      type: DEACTIVATE_PARTNER_SSO_TOKEN,
      payload: fetch(`${getHost()}/resellers/${partnerId}/sso-token`, {
        method: "DELETE",
      }).then((res) => res.json()),
    });
}

export function fetchPartnersById(partnerIds = []) {
  const promises = partnerIds.map((id) =>
    fetch(`${getHost()}/resellers/${id}`).then((res) => res.json()),
  );
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNERS_BY_ID,
      payload: Promise.all(promises),
    });
}

export function fetchPartnerUsers(partnerId, params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNER_USERS,
      meta: { partnerId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users?${urlParams}`).then((res) =>
        res.json(),
      ),
    });
}

export function createPartnerUser(partnerId, body) {
  return (dispatch) =>
    dispatch({
      type: CREATE_PARTNER_USER,
      meta: { partnerId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users`, {
        method: "POST",
        body: JSON.stringify(body),
      }).then((res) => res.json()),
    });
}

export function makePartnerUserPrimary(partnerId, userId) {
  return (dispatch) =>
    dispatch({
      type: MAKE_PARTNER_USER_PRIMARY,
      meta: { partnerId, userId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users/${userId}/primary`, {
        method: "POST",
      }).then((res) => res.json()),
    });
}

export function sendResetPasswordPartnerUser(partnerId, userId) {
  return (dispatch) =>
    dispatch({
      type: SEND_RESET_PASSWORD_PARTNER_USER,
      meta: { partnerId, userId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users/${userId}/reset-password`, {
        method: "POST",
      }).then((res) => res.json()),
    });
}

export function deletePartnerUser(partnerId, userId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_PARTNER_USER,
      meta: { partnerId, userId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users/${userId}`, {
        method: "DELETE",
      }).then((res) => res.json()),
    });
}

export function fetchPartnerUser(partnerId, userId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNER_USER,
      meta: { partnerId, userId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users/${userId}`).then((res) =>
        res.json(),
      ),
    });
}

export function updatePartnerUser(partnerId, userId, body) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_PARTNER_USER,
      meta: { partnerId, userId },
      payload: fetch(`${getHost()}/resellers/${partnerId}/users/${userId}`, {
        method: "PATCH",
        body: JSON.stringify(body),
      }).then((res) => res.json()),
    });
}

export function removeDeletePartnerError() {
  return (dispatch) => dispatch({ type: REMOVE_DELETE_PARTNER_ERROR });
}

export function fetchPartnersSenderEmails() {
  return (dispatch) =>
    dispatch({
      type: FETCH_PARTNERS_SENDER_EMAILS,
      payload: fetch(`${getHost()}/resellers/sender-emails`).then((res) => res.json()),
    });
}
