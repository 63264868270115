import { createAudience, receiveAudience } from "./audience";
import fetch, { getHost } from "./fetch";
import { linkCampaign } from "./locations";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const RECEIVE_CAMPAIGN = "RECEIVE_CAMPAIGN";
export const SCHEDULE_TASK = "SCHEDULE_TASK";
export const RECEIVE_CAMPAIGN_STATS = "RECEIVE_CAMPAIGN_STATS";
export const RECEIVE_CAMPAIGN_LOGS = "RECEIVE_CAMPAIGN_LOGS";
export const RECEIVE_LOGS_STATS = "RECEIVE_LOGS_STATS";
export const REQUEST_CAMPAIGN_AUDIENCES = "REQUEST_CAMPAIGN_AUDIENCES";
export const RECEIVE_CAMPAIGN_AUDIENCES = "RECEIVE_CAMPAIGN_AUDIENCES";

export const eventTypesMap = {
  delivery: "reach",
  thumbs_up: "positive",
  thumbs_down: "negative",
};

const campaignsUrl = () => `${getHost()}/campaigns`;
const tasksUrl = () => `${getHost()}/tasks`;
const eventsUrl = () => `${getHost()}/events`;

export function createCampaign(campaign) {
  return {
    type: CREATE_CAMPAIGN,
    payload: fetch(campaignsUrl(), {
      method: "POST",
      body: JSON.stringify(campaign),
    }).then((response) => response.json()),
  };
}

export function receiveCampaign(campaign) {
  return {
    type: RECEIVE_CAMPAIGN,
    campaign,
  };
}

export function receiveStats(campaignId, eventTypes, meta) {
  return {
    type: RECEIVE_CAMPAIGN_STATS,
    campaignId,
    eventTypes,
    meta,
  };
}

export function receiveLogs(campaignId, data) {
  return {
    type: RECEIVE_CAMPAIGN_LOGS,
    campaignId,
    data,
  };
}

export function receiveLogsStats(campaignId, messageId, data) {
  return {
    type: RECEIVE_LOGS_STATS,
    campaignId,
    messageId,
    data,
  };
}

export function receiveCampaignAudiences(campaignId, audiences) {
  return {
    type: RECEIVE_CAMPAIGN_AUDIENCES,
    campaignId,
    audiences,
  };
}

export function findCampaign(id) {
  return (dispatch) =>
    fetch(`${campaignsUrl()}/${id}`)
      .then((response) => response.json())
      .then((json) => dispatch(receiveCampaign(json)));
}

export function findCampaigns(ids) {
  return (dispatch) =>
    fetch(`${campaignsUrl()}?ids=${ids.join(",")}`)
      .then((response) => response.json())
      .then((json) => json.data.forEach((campaign) => dispatch(receiveCampaign(campaign))));
}

export function updateCampaign(id, campaign) {
  return (dispatch) =>
    fetch(`${campaignsUrl()}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(campaign),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveCampaign(json)));
}

export function findEvents(campaignId, filters = {}) {
  return (dispatch) => {
    let query = `campaign_id=${campaignId}`;
    Object.keys(filters).forEach((key) => {
      query = `${query}&${key}=${filters[key]}`;
    });
    return fetch(`${eventsUrl()}?${query}`)
      .then((response) => response.json())
      .then((json) =>
        dispatch(receiveStats(campaignId, filters.type.split(","), json.meta[campaignId])),
      );
  };
}

export function fetchLogStats(campaignId, messageId, filters = {}) {
  return (dispatch) => {
    let query = `campaign_id=${campaignId}&attributes[message_id]=${messageId}`;
    Object.keys(filters).forEach((key) => {
      query = `${query}&${key}=${filters[key]}`;
    });
    return fetch(`${eventsUrl()}?${query}`)
      .then((response) => response.json())
      .then((json) => dispatch(receiveLogsStats(campaignId, messageId, json)));
  };
}

export function fetchCampaignLogs(campaignId, filters = {}) {
  return (dispatch) => {
    let query = `campaign_id=${campaignId}`;
    Object.keys(filters).forEach((key) => {
      query = `${query}&${key}=${filters[key]}`;
    });
    return fetch(`${eventsUrl()}?${query}`)
      .then((response) => response.json())
      .then((json) => dispatch(receiveLogs(campaignId, json)));
  };
}

export function scheduleTask(audienceId, sendAt) {
  return {
    type: SCHEDULE_TASK,
    payload: fetch(tasksUrl(), {
      method: "POST",
      body: JSON.stringify({ audience_id: audienceId, send_at: sendAt }),
    }).then((response) => response.json()),
  };
}

export function fetchAudiences(campaignId) {
  return (dispatch) =>
    fetch(`${campaignsUrl()}/${campaignId}/audiences`)
      .then((response) => response.json())
      .then((audiences) => {
        dispatch(
          receiveCampaignAudiences(
            campaignId,
            audiences.map(({ id }) => id),
          ),
        );
        audiences.forEach((audience) => dispatch(receiveAudience(audience)));
        return audiences;
      });
}

/**
 * Creates a campaign and links it to a specified location.
 * If campaign has custom audience than the recipients will be added to a new
 * audience list and sending of the campaign will be started immediately.
 * @param locationId
 * @param campaign
 * @param recipients
 * @param linkage
 * @returns {function(*=)}
 */
export function createAndLinkCampaign({ locationId, campaign, recipients, linkage }) {
  return (dispatch) =>
    dispatch(createCampaign(campaign)).then(({ value: created }) => {
      const promises = [];
      if (linkage.audience_type === "custom") {
        // create audience and start sending campaign immediately
        const runCampaignPromise = dispatch(
          createAudience({
            campaign_id: created.id,
            recipients,
          }),
        ).then(({ value: audienceId }) => {
          if (campaign.status === "active") {
            return (
              dispatch(scheduleTask(audienceId, new Date()))
                // mark campaign as completed right away
                .then(() => dispatch(updateCampaign(created.id, { status: "completed" })))
            );
          }
          return Promise.resolve();
        });
        promises.push(runCampaignPromise);
      }
      promises.push(dispatch(linkCampaign(locationId, { ...linkage, campaign_id: created.id })));
      return Promise.all(promises).then(() => Promise.resolve(created.id));
    });
}
