import { loadingBarReducer } from "react-redux-loading-bar";
import { combineReducers } from "redux";
import { i18nState } from "redux-i18n";

import accounts from "./accounts";
import {
  campaigns,
  statsByCampaignId,
  locationCampaigns,
  audiencesByCampaignId,
  audiences,
} from "./campaigns";
import { selectedFilters, shortUrls } from "./common";
import {
  competitorsById,
  competitorsRatingsById,
  competitorsStatsById,
  competitorsRanksById,
} from "./competitors";
import configuration from "./configuration";
import {
  accountsById,
  selectedCustomer,
  customersById,
  selectedLocation,
  customerLocationsById,
  customerCampaignsById,
  customerProducts,
  bsp,
} from "./customer";
import files from "./files";
import * as listingManager from "./listings";
import {
  properties,
  locationSubscriptionsById,
  locationsById,
  locationCampaignLinkageById,
  propertySearch,
  onboardingInsights,
  locationEvents,
} from "./locations";
import { propertyReducer, competitorIds, places, placesDetails } from "./onboarding";
import {
  statsByFilters,
  statsOverviewGeneralByFilters,
  statsAllTimesByFilters,
  statsUnansweredByFilters,
  statsDetailsByFilters,
  statsClustersByFilters,
  overviewReviewsByFilters,
  rankingsByFilters,
} from "./overview";
import pagination from "./pagination";
import partners from "./partners";
import { selectedReviewFilters, statsReviewByFilters, reviewsByFilters } from "./review";
import { propertySettings, competitorSettings } from "./settings";
import storage from "./storage";
import subscriptions from "./subscriptions";
import { venues, wifi, devices, locations } from "./venues";
import webOptimizer from "./webOptimizer";

const rootReducer = combineReducers({
  accountsById,
  bsp,
  property: propertyReducer,
  subscriptions,
  i18nState,
  loadingBar: loadingBarReducer,
  competitorIds,
  places,
  placesDetails,
  listingManager: combineReducers(listingManager),
  selectedCustomer,
  selectedLocation,
  customersById,
  customerLocationsById,
  customerCampaignsById,
  customerProducts,
  locationCampaignLinkageById,
  propertySearch,
  properties,
  locationsById,
  locationEvents,
  onboardingInsights,
  audiences,
  locationSubscriptionsById,
  campaigns,
  audiencesByCampaignId,
  shortUrls,
  locationCampaigns,
  statsByCampaignId,
  statsByFilters,
  statsOverviewGeneralByFilters,
  statsAllTimesByFilters,
  statsUnansweredByFilters,
  statsDetailsByFilters,
  selectedFilters,
  competitorsById,
  competitorsRatingsById,
  competitorsStatsById,
  competitorsRanksById,
  selectedReviewFilters,
  statsReviewByFilters,
  statsClustersByFilters,
  rankingsByFilters,
  competitorSettings,
  propertySettings,
  overviewReviewsByFilters,
  storage,
  reviewsByFilters,
  pagination,
  configuration,
  venues,
  wifi,
  devices,
  accounts,
  webOptimizer,
  partners,
  files,
  locations,
});

export default rootReducer;
