import capitalize from "lodash/capitalize";
import get from "lodash/get";
import last from "lodash/last";
import orderBy from "lodash/orderBy";

const MENTIONS_COUNT = 10;
const TOPICS_COUNT = 6;

export const formatOptions = (options) =>
  Object.keys(options)
    .map((key) => ({ key, value: options[key] }))
    .sort((optionA, optionB) => optionB.value - optionA.value);

const formatTrends = (trends) =>
  Object.keys(trends).map((trend) => ({
    subject: capitalize(trend),
    countPositive: trends[trend].cp,
    countNegative: trends[trend].cn,
    totalCount: trends[trend].cp + trends[trend].cn,
  }));

export const formatTopics = (trends, topicsCount = TOPICS_COUNT) => {
  const formattedTopics = formatTrends(trends);

  return formattedTopics
    .sort((trendA, trendB) => trendB.totalCount - trendA.totalCount)
    .splice(0, topicsCount)
    .map((t) => ({ ...t, value: t.totalCount }));
};

export const formatMentions = (trends, mentionsCount = MENTIONS_COUNT) => {
  const formattedTrends = formatTrends(trends);
  const positiveTrends = formattedTrends
    .sort((trendA, trendB) => trendB.countPositive - trendA.countPositive)
    .slice(0, mentionsCount)
    .map((t) => ({ ...t, positive: true, value: t.countPositive }));
  const negativeTrends = formattedTrends
    .sort((trendA, trendB) => trendB.countNegative - trendA.countNegative)
    .slice(0, mentionsCount)
    .map((t) => ({ ...t, positive: false, value: t.countNegative }));

  const allTrends = orderBy(positiveTrends.concat(negativeTrends), (trend) => trend.value, "desc");

  const max = Math.abs(get(allTrends, "[0].value", 1));
  return allTrends.map((t) => ({ ...t, barWidth: `${(t.value * 100) / max}%` }));
};

export const getScore = (scoreObject, returnVal) =>
  get(scoreObject, "gen.scores") || get(scoreObject, "amb.scores") || returnVal;

/**
 * Combines channel URL with the health status.
 * Formats { yelp: ['http://yelp.com/property'] } to { yelp: { url: 'http://yelp.com/property', health: 'ready' } }
 * @param channels
 * @param health
 * @param info
 */
export const formatChannels = (channels = {}, health = {}, info = {}) => {
  const { isFetching, channels: channelHealth = {} } = health;
  return Object.keys(channels).reduce((obj, key) => {
    const url = last(get(channels, key, []));
    const { crawling } = last(get(info, key, [])) || {};
    return {
      ...obj,
      [key]: { url, health: isFetching ? "loading" : get(channelHealth, key, "unknown"), crawling },
    };
  }, {});
};

/**
 * Parses Link header used for pagination
 * @param header
 * @returns {{}}
 */
export function parseLinkHeader(header) {
  if (!header || !header.length) {
    return [];
  }

  // Split parts by comma
  const parts = header.split(",");
  const links = [];
  // Parse each part into a named link
  for (let i = 0; i < parts.length; i += 1) {
    const section = parts[i];
    const [, uri, , name, , title] = section.match(/<(.*?)>(;\srel="(.*?)")?(;\stitle="(.*?)")?/);
    links.push({ name, uri, title });
  }
  return links;
}
