import concat from "lodash/concat";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import uniqBy from "lodash/uniqBy";
import { ActionType } from "redux-promise-middleware";

import {
  RECEIVE_CUSTOMER,
  RECEIVE_CUSTOMER_CAMPAIGNS,
  RECEIVE_CUSTOMER_LOCATIONS,
  RECEIVE_CUSTOMER_PRODUCTS,
  RECEIVE_LOCATION_UPDATE,
  REQUEST_CUSTOMER,
  REQUEST_CUSTOMER_ACCOUNTS,
  REQUEST_CUSTOMER_BSP,
  REQUEST_CUSTOMER_CAMPAIGNS,
  REQUEST_CUSTOMER_LOCATIONS,
  REQUEST_CUSTOMER_PRODUCTS,
  REQUEST_LOCATION_UPDATE,
  SELECT_CUSTOMER,
  SELECT_LOCATION,
} from "../actions";

export function selectedCustomer(state = "1", action) {
  switch (action.type) {
    case SELECT_CUSTOMER:
      return action.customerId;
    default:
      return state;
  }
}

function customers(
  state = {
    isFetching: false,
    didInvalidate: false,
    customer: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CUSTOMER:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_CUSTOMER:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        customer: action.customer,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

export function customersById(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CUSTOMER:
    case REQUEST_CUSTOMER:
      return {
        ...state,
        [action.customerId]: customers(state[action.customerId], action),
      };
    default:
      return state;
  }
}

export function selectedLocation(state = null, action) {
  switch (action.type) {
    case SELECT_LOCATION:
      return action.locationId;
    default:
      return state;
  }
}

function customerLocations(
  state = {
    isFetching: false,
    didInvalidate: false,
    customerLocations: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CUSTOMER_LOCATIONS:
    case REQUEST_LOCATION_UPDATE:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_CUSTOMER_LOCATIONS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        customerLocations: uniqBy(
          concat(state.customerLocations, action.customerLocations),
          (location) => location.id,
        ),
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_LOCATION_UPDATE: {
      const mergeNewLocation = merge(
        state.customerLocations.find((location) => location.id === action.location.id),
        action.location,
      );
      const newCustomerLocations = uniqBy(
        concat(mergeNewLocation, state.customerLocations),
        (location) => location.id,
      );

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        customerLocations: newCustomerLocations,
        lastUpdated: action.receivedAt,
      };
    }
    default:
      return state;
  }
}

export function customerLocationsById(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CUSTOMER_LOCATIONS:
    case REQUEST_CUSTOMER_LOCATIONS:
    case REQUEST_LOCATION_UPDATE:
    case RECEIVE_LOCATION_UPDATE:
      return {
        ...state,
        [action.customerId]: customerLocations(state[action.customerId], action),
      };
    default:
      return state;
  }
}

export function accountsById(state = {}, action) {
  switch (action.type) {
    case `${REQUEST_CUSTOMER_ACCOUNTS}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: false,
      };
    case `${REQUEST_CUSTOMER_ACCOUNTS}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: true,
      };
    case `${REQUEST_CUSTOMER_ACCOUNTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        errors: false,
        accounts: {
          ...state.accounts,
          ...action.payload.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
        },
      };
    default:
      return state;
  }
}

export function bsp(state = {}, action) {
  switch (action.type) {
    case `${REQUEST_CUSTOMER_BSP}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
      };
    case `${REQUEST_CUSTOMER_BSP}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
      };
    case `${REQUEST_CUSTOMER_BSP}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        bsp: action.payload.data.reduce((acc, b) => ({ ...acc, [b.id]: b }), {}),
      };
    default:
      return state;
  }
}

function campaigns(
  state = {
    isFetching: false,
    didInvalidate: false,
    campaigns: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CUSTOMER_CAMPAIGNS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_CUSTOMER_CAMPAIGNS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        campaigns: action.campaigns,
      };
    default:
      return state;
  }
}

export function customerCampaignsById(state = {}, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_CAMPAIGNS:
    case RECEIVE_CUSTOMER_CAMPAIGNS:
      return {
        ...state,
        [action.customerId]: campaigns(state[action.customerId], action),
      };
    default:
      return state;
  }
}

function products(
  state = {
    isFetching: false,
    products: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CUSTOMER_PRODUCTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CUSTOMER_PRODUCTS:
      return {
        ...state,
        isFetching: false,
        products: isArray(action.products) ? action.products : Object.values(action.products),
      };
    default:
      return state;
  }
}

export function customerProducts(state = {}, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_PRODUCTS:
    case RECEIVE_CUSTOMER_PRODUCTS:
      return products(state[action.customerId], action);
    default:
      return state;
  }
}
