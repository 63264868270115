import {
  PROPERTY_UPDATE_FAILED,
  PROPERTY_UPDATE_SUCCESSFUL,
  RECEIVE_PROPERTY_INFO,
  UPDATE_COMPETITORS_FAILED,
  UPDATE_COMPETITORS_LOADING,
  UPDATE_COMPETITORS_SUCCESS,
} from "../actions";
import { PROPERTY_UPDATE_LOADING } from "../actions/onboarding";
import { allChannels } from "../utils/channels";

const initialState = {
  competitors: {},
  errors: [],
  property: 0,
  fetching: false,
};

export function propertySettings(state = { errors: {} }, { type, result, errors }) {
  switch (type) {
    case RECEIVE_PROPERTY_INFO:
      return {
        ...state,
        errors: {},
        isFetching: false,
        result: {},
      };
    case PROPERTY_UPDATE_LOADING:
      return {
        ...state,
        errors: {},
        isFetching: true,
        result,
      };
    case PROPERTY_UPDATE_SUCCESSFUL:
      return {
        ...state,
        errors: {},
        isFetching: false,
        result,
      };
    case PROPERTY_UPDATE_FAILED:
      return {
        ...state,
        result,
        isFetching: false,
        errors: errors.reduce((obj, err) => {
          const field = allChannels.reduce(
            (name, channel) => (err.message.indexOf(channel.name) >= 0 ? channel.name : name),
            "other",
          );
          return { ...obj, [field]: err.message };
        }, {}),
      };
    default:
      return state;
  }
}

export function competitorSettings(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PROPERTY_INFO:
      return {
        ...state,
        isUpdated: false,
        isFetching: false,
        errors: [],
      };
    case UPDATE_COMPETITORS_LOADING:
      return {
        ...state,
        isFetching: true,
        isUpdated: false,
        errors: [],
      };
    case UPDATE_COMPETITORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        errors: [],
      };
    case UPDATE_COMPETITORS_FAILED:
      return {
        ...state,
        isFetching: false,
        isUpdated: false,
        errors: [action.error],
      };
    default:
      return state;
  }
}
