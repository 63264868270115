import React, { Suspense, useEffect } from "react";

import fontawesome from "@fortawesome/fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { init, Replay } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { GoogleLibProvider } from "context/GoogleLibContext";
import { useTranslation } from "react-i18next";
import { Switch, Route } from "react-router";
import { setLocale } from "yup";

import useCSRFCookie from "hooks/queries/useCSRFCookieQuery";
import { useCookieWatcher } from "hooks/useCookieWatcher";

import EnableTeyaSkin from "utils/EnableTeyaSkin";
import { getSetLanguage } from "utils/languages";

import SentryErrorBoundary from "./components/commons/ErrorBoundary";
import LoadingPopup from "./components/commons/LoadingPopup";
import PopupPage from "./components/commons/PopupPage";
import "./styles/global.scss";
import "./styles/tailwind.css";
import "./styles/teya.scss";
import log, { LOG_LEVEL } from "./utils/logger";

const CustomerRoute = React.lazy(() => import("./routes/Customer"));
const OverviewContainerRoute = React.lazy(() => import("./routes/OverviewContainer"));
const ListingsRoute = React.lazy(() => import("./routes/Listings"));
const NotFoundRoute = React.lazy(() => import("./routes/NotFound"));
const Wifi = React.lazy(() => import("./routes/wifi"));

// Temporary solution to separate all pages customized with carbon design
const CarbonRoutes = React.lazy(() => import("./routes/carbon"));

library.add(fas, far);
fontawesome.config.observeMutations = true;

// Global listener for errors and external logging
if (typeof window !== "undefined") {
  window.addEventListener("onerror", (msg, url, lineNo, columnNo, error) => {
    const string = msg.toLowerCase();
    const substring = "script error";
    if (string.indexOf(substring) > -1) {
      log(
        "Script error, no details",
        {
          errorObj: error,
          msg,
          url,
          lineNo,
          columnNo,
        },
        LOG_LEVEL.ERROR,
      );
    } else {
      log(
        msg,
        {
          errorObj: error,
          url,
          lineNo,
          columnNo,
        },
        LOG_LEVEL.ERROR,
      );
    }
    return false;
  });
}

if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new Integrations.BrowserTracing(),
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });
}

const lang = getSetLanguage();

const App = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // sets default messages with translations to yup validations
    setLocale({
      mixed: {
        required: t("This field is required"),
      },
      string: {
        email: t("Invalid email address"),
        url: t("This is not a valid link, copy and paste from your browser"),
      },
    });
  });

  const csrfTokenExist = useCookieWatcher("XSRF-TOKEN", 500);
  useCSRFCookie(csrfTokenExist);

  const renderLoading = () => (
    <PopupPage color="white">
      <LoadingPopup />
    </PopupPage>
  );

  return (
    <SentryErrorBoundary>
      <GoogleLibProvider language={lang}>
        <EnableTeyaSkin>
          <Switch>
            <Route
              path="/admin/app"
              render={({ match: { path } }) => (
                <Suspense fallback={renderLoading()}>
                  <Switch>
                    <Route
                      path={`${path}/customer/:customerId/location/:locationId/listings`}
                      component={ListingsRoute}
                    />
                    <Route
                      path={`${path}/customer/:customerId/location/:locationId/overview`}
                      component={OverviewContainerRoute}
                    />
                    <Route
                      path={`${path}/customer/:customerId/location/:locationId`}
                      component={CustomerRoute}
                    />
                    <Route
                      path={`${path}/customer/:customerId/listings`}
                      component={ListingsRoute}
                    />
                    <Route
                      path={`${path}/customer/:customerId/overview`}
                      component={OverviewContainerRoute}
                    />
                    <Route path={`${path}/customer/:customerId`} component={CustomerRoute} />
                    <Route path={path} component={CarbonRoutes} />

                    <Route component={NotFoundRoute} />
                  </Switch>
                </Suspense>
              )}
            />
            <Route path="/wifi" component={Wifi} />
          </Switch>
        </EnableTeyaSkin>
      </GoogleLibProvider>
    </SentryErrorBoundary>
  );
};

export default App;
