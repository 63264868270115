import omitBy from "lodash/omitBy";
import { stringify } from "query-string";

import fetch, { getHost } from "./fetch";

export const FETCH_VENUE = "FETCH_VENUE";
export const FETCH_ALL_VENUES = "FETCH_ALL_VENUES";
export const FETCH_WIFI = "FETCH_WIFI";
export const FETCH_LOCATION_DEVICES = "FETCH_LOCATION_DEVICES";
export const ASSIGN_LOCATION_DEVICE = "ASSIGN_LOCATION_DEVICE";
export const REMOVE_LOCATION_DEVICE = "REMOVE_LOCATION_DEVICE";
export const UPDATE_VENUE = "UPDATE_VENUE";
export const UPDATE_NETWORK_ACCESS = "UPDATE_NETWORK_ACCESS";
export const EXECUTE_DEVICE_DEBUG = "EXECUTE_DEVICE_DEBUG";
export const UPDATE_DEVICE_SETTINGS = "UPDATE_DEVICE_SETTINGS";
export const CREATE_ACCOUNT_LOCATION = "CREATE_ACCOUNT_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const CLEAR_DELETE_LOCATION_ERROR = "CLEAR_DELETE_LOCATION_ERROR";

const devicesUrl = (id) => `${getHost()}/locations/${id}/wifi/devices`;

export function fetchVenue(id) {
  return (dispatch) =>
    dispatch({
      type: FETCH_VENUE,
      payload: fetch(`${getHost()}/venues/${id}`).then((res) => res.json()),
    });
}

export function fetchAllVenues(params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    order: "",
    query: "",
    products: "",
  };
  const parametersToSend = { ...defaultParams, ...params };
  const urlParams = stringify(omitBy(parametersToSend, (val) => val === ""));
  return (dispatch) =>
    dispatch({
      type: FETCH_ALL_VENUES,
      payload: fetch(`${getHost()}/locations?${urlParams}`).then((res) => res.json()),
    });
}

export function fetchWifi(id) {
  return (dispatch) =>
    dispatch({
      type: FETCH_WIFI,
      payload: fetch(`${getHost()}/locations/${id}/wifi`).then((res) => res.json()),
    });
}

export function updateVenue(id, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_VENUE,
      payload: fetch(`${getHost()}/venues/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function updateNetworkAndAccess(id, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_NETWORK_ACCESS,
      payload: fetch(`${getHost()}/locations/${id}/wifi/network-access`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function updateGuestPortal(id, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_VENUE,
      payload: fetch(`${getHost()}/locations/${id}/wifi/guestportal`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function updateGuestHours(id, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_VENUE,
      payload: fetch(`${getHost()}/locations/${id}/wifi/guesthours`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function updateReviewExpress(id, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_VENUE,
      payload: fetch(`${getHost()}/locations/${id}/wifi/review-express`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function fetchDevices(id) {
  return (dispatch) =>
    dispatch({
      type: FETCH_LOCATION_DEVICES,
      payload: fetch(devicesUrl(id)).then((res) => res.json()),
      meta: { locationId: id },
    });
}

export function assignDevice(locationId, deviceId) {
  return (dispatch) =>
    dispatch({
      type: ASSIGN_LOCATION_DEVICE,
      payload: fetch(`${devicesUrl(locationId)}/assign`, {
        method: "POST",
        body: JSON.stringify({ device_id: deviceId }),
      }).then((res) => res.json()),
      meta: { locationId },
    });
}

export function removeDevice(locationId, deviceId) {
  return (dispatch) =>
    dispatch({
      type: REMOVE_LOCATION_DEVICE,
      payload: fetch(`${devicesUrl(locationId)}/remove`, {
        method: "DELETE",
        body: JSON.stringify({ device_id: deviceId }),
      }).then((res) => res.json()),
      meta: { locationId, deviceId },
    });
}

export function executeDeviceDebug(locationId, deviceId, data) {
  return (dispatch) =>
    dispatch({
      type: EXECUTE_DEVICE_DEBUG,
      meta: {
        device_id: deviceId,
        location_id: locationId,
      },
      payload: fetch(`${getHost()}/locations/${locationId}/wifi/devices/debug`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function updateDeviceSettings(locationId, deviceId, data) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_DEVICE_SETTINGS,
      payload: fetch(`${devicesUrl(locationId)}/${deviceId}`, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
      meta: { deviceId },
    });
}

export function createAccountLocation(accountId, data) {
  return (dispatch) =>
    dispatch({
      type: CREATE_ACCOUNT_LOCATION,
      meta: { accountId },
      payload: fetch(`${getHost()}/venues`, {
        method: "POST",
        body: JSON.stringify({ ...data, account_id: Number(accountId) }),
      }).then((res) => res.json()),
    });
}

export function deleteVenue(locationId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_LOCATION,
      meta: { locationId },
      payload: fetch(`${getHost()}/venues/${locationId}`, { method: "DELETE" }).then((res) =>
        res.json(),
      ),
    });
}

export function clearDeleteVenueError(locationId) {
  return (dispatch) =>
    dispatch({
      type: CLEAR_DELETE_LOCATION_ERROR,
      meta: { locationId },
    });
}
