import castArray from "lodash/castArray";
import moment from "moment";

import { defaultReviewFilters } from "../utils/filters";
import { excludedNetworks } from "../utils/reviewUtils";
import { selectFilters } from "./common";
import { fetchCompetitorsRatings } from "./competitors";
import fetch, { getHost, handleFetchError } from "./fetch";
import { fetchPropertyInfo } from "./locations";
import { fetchContents, fetchReviews } from "./review";

/* global enqueueResourceRequest */
export const REQUEST_STATS = "REQUEST_STATS";
export const RECEIVE_STATS = "RECEIVE_STATS";
export const REQUEST_STATS_OVERVIEW_GENERAL = "REQUEST_STATS_OVERVIEW_GENERAL";
export const RECEIVE_STATS_OVERVIEW_GENERAL = "RECEIVE_STATS_OVERVIEW_GENERAL";
export const REQUEST_STATS_ALL_TIMES = "REQUEST_STATS_ALL_TIMES";
export const RECEIVE_STATS_ALL_TIMES = "RECEIVE_STATS_ALL_TIMES";
export const REQUEST_STATS_UNANSWERED = "REQUEST_STATS_UNANSWERED";
export const RECEIVE_STATS_UNANSWERED = "RECEIVE_STATS_UNANSWERED";
export const REQUEST_STATS_DETAILS = "REQUEST_STATS_DETAILS";
export const RECEIVE_STATS_DETAILS = "RECEIVE_STATS_DETAILS";
export const REQUEST_STATS_CLUSTERS = "REQUEST_STATS_CLUSTERS";
export const RECEIVE_STATS_CLUSTERS = "RECEIVE_STATS_CLUSTERS";
export const REQUEST_OVERVIEW_REVIEWS = "REQUEST_OVERVIEW_REVIEWS";
export const RECEIVE_OVERVIEW_REVIEWS = "RECEIVE_OVERVIEW_REVIEWS";

const reviewsUrl = () => `${getHost()}/reviews`;
const contentsDataUrl = () => `${reviewsUrl()}/contentsData`;

export function requestGenericStats(filters, type) {
  const { overwrite, ...otherFilters } = filters;
  return { type, filters: otherFilters, overwrite };
}

export function receiveGenericStats(filters, stats, type) {
  return {
    type,
    stats,
    filters,
    receivedAt: Date.now(),
  };
}

function requestStatsOverviewGeneral(filters) {
  return requestGenericStats(filters, REQUEST_STATS_OVERVIEW_GENERAL);
}

function receiveStatsOverviewGeneral(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_STATS_OVERVIEW_GENERAL);
}

function requestStatsAllTimes(filters) {
  return requestGenericStats(filters, REQUEST_STATS_ALL_TIMES);
}

function receiveStatsAllTimes(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_STATS_ALL_TIMES);
}

function requestStatsUnanswered(filters) {
  return requestGenericStats(filters, REQUEST_STATS_UNANSWERED);
}

function receiveStatsUnanswered(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_STATS_UNANSWERED);
}

function requestStatsDetails(filters) {
  return requestGenericStats(filters, REQUEST_STATS_DETAILS);
}

function receiveStatsDetails(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_STATS_DETAILS);
}

function requestStatsClusters(filters) {
  return requestGenericStats(filters, REQUEST_STATS_CLUSTERS);
}

function receiveStatsClusters(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_STATS_CLUSTERS);
}

function requestReviews(filters) {
  return requestGenericStats(filters, REQUEST_OVERVIEW_REVIEWS);
}

function receiveReviews(filters, stats) {
  return receiveGenericStats(filters, stats, RECEIVE_OVERVIEW_REVIEWS);
}

function fetchStats({ filters, url = contentsDataUrl(), parameters = {} }) {
  const { monitorId, language, country, timeRange } = filters;
  const { network } = parameters; // handling of filter differences in overview/review
  const formattedFilters = {
    orderBy: "date",
    to: timeRange.endDate,
    from: timeRange.startDate,
    monitor_ids: [monitorId],
    branches: defaultReviewFilters.branches,
  };
  if (country) {
    formattedFilters.countries = castArray(country);
  }
  if (network) {
    formattedFilters.networks = castArray(network);
  }
  if (language) {
    formattedFilters.languages = castArray(language);
  }
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      filters: {
        ...formattedFilters,
        ...parameters,
      },
    }),
  }).then((response) => response.json());
}

function fetchStatsOverviewGeneral({ filters, parameters, dispatch }) {
  dispatch(requestStatsOverviewGeneral(filters));
  return fetchStats({
    filters,
    parameters: {
      ...parameters,
      compute: [
        "count",
        "unanswerCounter",
        "sentimentSerie",
        "trends",
        "dishes",
        "streamCounters",
        "sentiment",
      ],
    },
  }).then((json) => dispatch(receiveStatsOverviewGeneral(filters, json)));
}

function fetchStatsAllTimes({ filters, parameters, dispatch }) {
  const formattedFilters = {
    ...filters,
    timeRange: `previous ${filters.timeRange}`,
  };
  dispatch(requestStatsAllTimes(filters));
  const to = filters.timeRange.startDate;
  return fetchStats({
    filters: formattedFilters,
    parameters: {
      ...parameters,
      to,
      from: to - Math.abs(filters.timeRange.startDate - filters.timeRange.endDate),
      compute: ["count", "unanswerCounter", "trends", "sentiment"],
    },
  }).then((json) => dispatch(receiveStatsAllTimes(filters, json)));
}

function fetchStatsUnanswered({ filters, parameters, dispatch }) {
  dispatch(requestStatsUnanswered(filters));
  return fetchStats({
    filters,
    parameters: {
      ...parameters,
      compute: ["unanswerCounter", "streamCounters"],
      unanswered: true,
      sentiment_ranges_stream: {
        negative: [0, 59.99],
        positive: [60, 100],
      },
    },
  }).then((json) => dispatch(receiveStatsUnanswered(filters, json)));
}

function fetchStatsDetails({ filters, parameters, dispatch }) {
  dispatch(requestStatsDetails(filters));
  return fetchStats({
    filters,
    parameters: {
      ...parameters,
      compute: ["count", "sentiment", "trends", "sentimentSerie"],
      groupBy: "network",
    },
  }).then((json) => dispatch(receiveStatsDetails(filters, json)));
}

export function fetchStatsClusters({ filters, parameters, dispatch, clusters }) {
  dispatch(requestStatsClusters(filters));
  return fetchStats({
    filters: {
      ...filters,
      timeRange: {
        ...filters.timeRange,
        startDate: moment.unix(filters.timeRange.startDate).subtract(90, "days").unix(),
      },
    },
    parameters: {
      ...parameters,
      compute: ["sentimentSerie"],
      groupBy: "cluster",
      clusters,
    },
  }).then((json) => dispatch(receiveStatsClusters(filters, json)));
}

function shouldFetchStats(stats) {
  if (!stats) {
    return true;
  }
  if (stats.isFetching) {
    return false;
  }
  return stats.didInvalidate;
}

function tryFetchStatsOverviewGeneral({ getState, ...params }) {
  const { selectedFilters, statsOverviewGeneralByFilters } = getState();
  const { key, ...filters } = selectedFilters;
  if (shouldFetchStats(statsOverviewGeneralByFilters[key])) {
    return enqueueResourceRequest((finished) => {
      fetchStatsOverviewGeneral({ ...params, filters })
        .then(finished)
        .catch(handleFetchError);
    });
  }
  return null;
}

function tryFetchStatsAllTimes({ getState, ...params }) {
  const { selectedFilters, statsAllTimesByFilters } = getState();
  const { key, ...filters } = selectedFilters;
  if (shouldFetchStats(statsAllTimesByFilters[key])) {
    return enqueueResourceRequest((finished) => {
      fetchStatsAllTimes({ ...params, filters })
        .then(finished)
        .catch(handleFetchError);
    });
  }
  return null;
}

function tryFetchStatsUnanswered({ getState, ...params }) {
  const { selectedFilters, statsUnansweredByFilters } = getState();
  const { key, ...filters } = selectedFilters;
  if (shouldFetchStats(statsUnansweredByFilters[key])) {
    return enqueueResourceRequest((finished) => {
      fetchStatsUnanswered({ ...params, filters })
        .then(finished)
        .catch(handleFetchError);
    });
  }
  return null;
}

function tryFetchStatsDetails({ getState, ...params }) {
  const { selectedFilters, statsDetailsByFilters } = getState();
  const { key, ...filters } = selectedFilters;
  if (shouldFetchStats(statsDetailsByFilters[key])) {
    return enqueueResourceRequest((finished) => {
      fetchStatsDetails({ ...params, filters })
        .then(finished)
        .catch(handleFetchError);
    });
  }
  return null;
}

function tryFetchStatsClusters({ getState, ...params }) {
  const { selectedFilters, statsClustersByFilters } = getState();
  const { key, ...filters } = selectedFilters;
  if (shouldFetchStats(statsClustersByFilters[key])) {
    return enqueueResourceRequest((finished) => {
      fetchStatsClusters({ ...params, filters }).then(finished);
    });
  }
  return null;
}

function shouldFetchReviewScore(getState) {
  const { selectedFilters, competitorsRatingsById } = getState();
  const ratings = competitorsRatingsById[selectedFilters.key];

  if (!ratings) {
    return true;
  }
  if (ratings.isFetching) {
    return false;
  }

  return ratings.didInvalidate;
}

function tryFetchReviewScoreData({ getState, ...params }) {
  const { selectedFilters } = getState();
  const { ...filters } = selectedFilters;

  if (shouldFetchReviewScore(getState)) {
    return enqueueResourceRequest((finished) => {
      fetchCompetitorsRatings({
        ...params,
        filters,
        currentMonitorId: filters.monitorId,
        monitorIds: [filters.monitorId],
      }).then(finished);
    });
  }
  return null;
}

function fetchStatsOverview({ filters, parameters, dispatch, getState }) {
  const promises = [
    tryFetchStatsOverviewGeneral({
      filters,
      parameters,
      dispatch,
      getState,
    }),
    tryFetchStatsAllTimes({
      filters,
      parameters,
      dispatch,
      getState,
    }),
    tryFetchStatsUnanswered({
      filters,
      parameters,
      dispatch,
      getState,
    }),
  ];
  return Promise.all(promises);
}

export function dispatchStatsOverview({ filters, parameters = {} }) {
  return (dispatch, getState) => {
    dispatch(selectFilters(filters));
    return fetchStatsOverview({
      filters,
      parameters,
      dispatch,
      getState,
    });
  };
}

export function dispatchStatsDetails({ filters, parameters = {} }) {
  return (dispatch, getState) => {
    const {
      selectedFilters: { monitorId },
      configuration: { reviewClusters = [] },
    } = getState();
    const getClusters = (type) => {
      if (reviewClusters.length === 0) {
        return ["hotel", "country_house"].includes(type)
          ? ["101", "105", "104"]
          : ["301", "302", "304"];
      }
      return Object.keys(reviewClusters);
    };
    return dispatch(fetchPropertyInfo(monitorId)).then((response) => {
      const { property: { channels, type } = {} } = response.property || {};
      // Filters out the networks that arent supposed to be in RM
      const networks = Object.keys(channels).filter(
        (network) => !excludedNetworks.concat("website").includes(network),
      );
      const params = { ...parameters, networks };
      const promises = [
        tryFetchStatsDetails({
          filters,
          parameters: params,
          dispatch,
          getState,
        }),
        tryFetchReviewScoreData({
          filters,
          parameters: params,
          dispatch,
          getState,
        }),
        tryFetchStatsClusters({
          filters,
          parameters,
          dispatch,
          getState,
          clusters: getClusters(type),
        }),
        response.property, // return property always in last
      ];
      return Promise.all(promises);
    });
  };
}

export function dispatchOverviewReviews({ filters: additionalFilters, parameters }) {
  return (dispatch, getState) => {
    const { ...filters } = getState().selectedFilters;
    dispatch(requestReviews({ ...filters, overwrite: true }));
    return fetchReviews({ filters: { ...filters, ...additionalFilters }, parameters })
      .then((json) =>
        fetchContents(json).then((response) => ({
          ...json,
          ...response,
        })),
      )
      .then((response) => dispatch(receiveReviews(filters, response)))
      .catch(handleFetchError);
  };
}

export function dispatchMoreOverviewReviews(contentsID, filters) {
  return (dispatch) => {
    dispatch(requestReviews(filters));
    return fetchContents({ contentsID }).then((response) =>
      dispatch(receiveReviews(filters, response)),
    );
  };
}
