import difference from "lodash/difference";
import memoizeOne from "memoize-one";

import { allChannels } from "./channelsConfig";
import { validIds } from "./general";

const POSITIVITY_POS = "p"; // positive
const POSITIVITY_NEG = "n"; // negative
const POSITIVITY_NEU = "x"; // neutral

export default function filtersReviewToKey({
  monitorId,
  timeRange,
  language,
  country,
  network,
  response,
  sentiment,
  orderBy,
  unanswered,
  dishes,
  arguments: args,
  keywords,
} = {}) {
  return [
    monitorId,
    timeRange ? Object.values(timeRange) : null,
    language,
    country,
    network,
    response,
    sentiment,
    orderBy,
    unanswered,
    dishes,
    args,
    keywords,
  ].join("_");
}

export const getFiltersByType = ({ type, title, positivity = POSITIVITY_NEU }) => {
  const filters = {};
  switch (type) {
    case "complaints":
      filters.unanswered = true;
      break;
    case "appreciated":
      filters.dishes = [title];
      break;
    case "users":
      filters.user_types = [title];
      break;
    case "mentions":
      filters.arguments = [title];
      if (positivity !== POSITIVITY_NEU) {
        filters.mentions = positivity ? [POSITIVITY_POS] : [POSITIVITY_NEG];
      }
      break;
    case "platform":
      filters.network = [title.toLowerCase()];
      break;
    case "reviews":
    default:
  }
  return filters;
};

export const filtersToTitle = (filters, t) => {
  let counter = true;
  let title = t("reviews received");
  if (filters.unanswered) {
    title = t("unanswered complaints");
    counter = true;
  }
  if (filters.dishes) {
    title = `${t("The most appreciated")}: ${filters.dishes[0]}`;
    counter = false;
  }

  if (filters.network) {
    title = `${t("Reviews from")}: ${filters.network[0]}`;
    counter = true;
  }

  if (filters.arguments) {
    title = `${t("Topic discussed by guests")}: ${filters.arguments[0]}`;
    counter = false;
  }

  return {
    counter,
    title,
  };
};

// List of all networks we don't want to show in RM
export const excludedNetworks = ["thefork", "instagram"];

// Creates a memoized list of all the networks we want to use in RM
const memoizedDiff = memoizeOne(difference);
export const reviewNetworks = memoizedDiff(allChannels, excludedNetworks);

export const getCampaignKey = (campaignIdBase, locationIdBase) => {
  let campaignKey = null;

  if (validIds([campaignIdBase, locationIdBase])) {
    campaignKey = btoa(
      JSON.stringify({
        campaign: String(campaignIdBase),
        location: String(locationIdBase),
      }),
    );
  }

  return campaignKey;
};
