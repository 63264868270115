import {
  RECEIVE_COUNTRIES,
  RECEIVE_REVIEW_CLUSTERS,
  REQUEST_COUNTRIES,
  REQUEST_REVIEW_CLUSTERS,
} from "../actions/configuration";

function reviewClusters(state = {}, action) {
  return {
    ...state,
    isFetching: false,
    reviewClusters: action.clusters,
  };
}

function countries(state = {}, action) {
  return {
    ...state,
    isFetching: false,
    countries: action.countries,
  };
}

export default function configuration(state = {}, action) {
  switch (action.type) {
    case REQUEST_REVIEW_CLUSTERS:
      return {
        ...state,
        reviewClusters: {},
        isFetching: true,
      };
    case REQUEST_COUNTRIES:
      return {
        ...state,
        countries: {},
        isFetching: true,
      };
    case RECEIVE_REVIEW_CLUSTERS:
      return reviewClusters(state, action);
    case RECEIVE_COUNTRIES:
      return countries(state, action);
    default:
      return state;
  }
}
