import { ActionType } from "redux-promise-middleware";

import { CREATE_SHORT_URL, SELECT_FILTERS } from "../actions";
import { filtersToKey } from "../utils/reducerUtils";

/* eslint-disable import/prefer-default-export */
export function selectedFilters(state = {}, action) {
  switch (action.type) {
    case SELECT_FILTERS:
      return {
        ...state,
        ...action.filters,
        key: filtersToKey({ ...state, ...action.filters }),
      };
    default:
      return state;
  }
}
/* eslint-enable */

export function shortUrls(state = {}, action) {
  switch (action.type) {
    case `${CREATE_SHORT_URL}_${ActionType.Rejected}`:
    case `${CREATE_SHORT_URL}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
      };
    case `${CREATE_SHORT_URL}_${ActionType.Fulfilled}`:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}
