import { RECEIVE_PAGINATION } from "../actions/pagination";

export default function pagination(state = {}, action) {
  switch (action.type) {
    case RECEIVE_PAGINATION:
      return {
        ...state,
        [action.key]: action.links,
      };
    default:
      return state;
  }
}
