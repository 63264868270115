import moment from "moment";

export const defaultReviewFilters = {
  timeRange: {
    startDate: moment().subtract(1, "year").startOf("day").unix(),
    endDate: moment().endOf("day").unix(),
  },
  orderBy: "date",
  country: [],
  language: [],
  network: [],
  response: [],
  sentiment: undefined,
  arguments: [],
  keywords: [],
  keyword_strategy: "OR",
  branches: ["reviews", "tagged_posts"],
  unanswered: false,
};

export const sentimentRange = {
  positive: [60, 100],
  negative: [0, 59.99],
};

export const userTypes = (t) => ({
  e: t("All"),
  o: t("Other"),
  f: t("Families"),
  c: t("Couples"),
  g: t("Friends/Groups"),
  a: t("Solo travelers"),
  b: t("Business trips"),
  r: t("Romantic getaway"),
});
