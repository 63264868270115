import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";

import {
  RECEIVE_OVERVIEW_REVIEWS,
  RECEIVE_STATS,
  RECEIVE_STATS_ALL_TIMES,
  RECEIVE_STATS_CLUSTERS,
  RECEIVE_STATS_DETAILS,
  RECEIVE_STATS_OVERVIEW_GENERAL,
  RECEIVE_STATS_UNANSWERED,
  REQUEST_OVERVIEW_REVIEWS,
  REQUEST_STATS,
  REQUEST_STATS_ALL_TIMES,
  REQUEST_STATS_CLUSTERS,
  REQUEST_STATS_DETAILS,
  REQUEST_STATS_OVERVIEW_GENERAL,
  REQUEST_STATS_UNANSWERED,
} from "../actions";
import { RECEIVE_RANKINGS } from "../actions/rankings";
import { filtersToKey } from "../utils/reducerUtils";

const customMerge = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return srcValue;
};

export function createStatsReducer(state, action, requestAction, receiveAction, mergeStats) {
  switch (action.type) {
    case requestAction:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case receiveAction:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
        stats: mergeStats
          ? mergeWith({ ...state.stats }, action.stats, customMerge)
          : {
              ...state.stats,
              ...action.stats,
            },
      };
    default:
      return state;
  }
}

export function createStatsReducerByFilters(
  state,
  action,
  requestAction,
  receiveAction,
  reducerFunction,
  options = {},
) {
  const { keyMapper, initialState } = {
    keyMapper: filtersToKey,
    initialState: {},
    ...options,
  };
  const key = keyMapper(action.filters);
  switch (action.type) {
    case requestAction:
      if (action.overwrite) {
        return {
          ...state,
          [key]: reducerFunction(initialState, action),
        };
      }
      return {
        ...state,
        [key]: reducerFunction(state[key] || initialState, action),
      };
    case receiveAction:
      return {
        ...state,
        [key]: reducerFunction(state[key] || initialState, action),
      };
    default:
      return state;
  }
}

function stats(state, action) {
  const requestAction = REQUEST_STATS;
  const receiveAction = RECEIVE_STATS;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS;
  const receiveAction = RECEIVE_STATS;
  const reducer = stats;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function statsOverviewGeneral(state, action) {
  const requestAction = REQUEST_STATS_OVERVIEW_GENERAL;
  const receiveAction = RECEIVE_STATS_OVERVIEW_GENERAL;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsOverviewGeneralByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS_OVERVIEW_GENERAL;
  const receiveAction = RECEIVE_STATS_OVERVIEW_GENERAL;
  const reducer = statsOverviewGeneral;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function statsAllTimes(state, action) {
  const requestAction = REQUEST_STATS_ALL_TIMES;
  const receiveAction = RECEIVE_STATS_ALL_TIMES;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsAllTimesByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS_ALL_TIMES;
  const receiveAction = RECEIVE_STATS_ALL_TIMES;
  const reducer = statsAllTimes;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function statsUnanswered(state, action) {
  const requestAction = REQUEST_STATS_UNANSWERED;
  const receiveAction = RECEIVE_STATS_UNANSWERED;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsUnansweredByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS_UNANSWERED;
  const receiveAction = RECEIVE_STATS_UNANSWERED;
  const reducer = statsUnanswered;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function statsDetails(state, action) {
  const requestAction = REQUEST_STATS_DETAILS;
  const receiveAction = RECEIVE_STATS_DETAILS;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsDetailsByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS_DETAILS;
  const receiveAction = RECEIVE_STATS_DETAILS;
  const reducer = statsDetails;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function statsClusters(state, action) {
  const requestAction = REQUEST_STATS_CLUSTERS;
  const receiveAction = RECEIVE_STATS_CLUSTERS;
  return createStatsReducer(state, action, requestAction, receiveAction);
}

export function statsClustersByFilters(state = {}, action) {
  const requestAction = REQUEST_STATS_CLUSTERS;
  const receiveAction = RECEIVE_STATS_CLUSTERS;
  const reducer = statsClusters;
  return createStatsReducerByFilters(state, action, requestAction, receiveAction, reducer);
}

function overviewReviews(state, action) {
  const requestAction = REQUEST_OVERVIEW_REVIEWS;
  const receiveAction = RECEIVE_OVERVIEW_REVIEWS;
  return createStatsReducer(state, action, requestAction, receiveAction, true);
}

const initialReviewStats = { initial: { stats: { contents: [], contentsID: [] } } };
export function overviewReviewsByFilters(state = initialReviewStats, action) {
  const request = REQUEST_OVERVIEW_REVIEWS;
  const receive = RECEIVE_OVERVIEW_REVIEWS;
  const reducer = overviewReviews;
  const initialState = initialReviewStats.initial;
  return createStatsReducerByFilters(state, action, request, receive, reducer, { initialState });
}

/**
 * Channel rankings mapped by filter key received from POST /channelsRanking
 * The response is formatted from
 * {
 *   "tripadvisor": {
 *       "ranking": [ 51 ],
 *       "total": [ 3508 ],
 *       "type": "Restaurants",
 *       "location": "Amsterdam"
 *   },
 *   "_timestamps": [ 1517353200 ]
 * }
 *
 * to
 * {
 *   "tripadvisor": {
 *       "ranking": { 1517353200: { rank: 51, total: 3508 } },
 *       "type": "Restaurants",
 *       "location": "Amsterdam"
 *   },
 * }
 * @param state
 * @param type
 * @param rankings
 * @param filters
 * @returns {{}}
 */
export function rankingsByFilters(state = {}, { type, rankings, filters }) {
  if (type === RECEIVE_RANKINGS) {
    const { _timestamps: timestamps, _aggregation, ...networks } = rankings;
    const formattedRankings = Object.keys(networks).reduce((result, network) => {
      const { ranking: rankingArray = [], total = [], ...other } = rankings[network];
      const ranking = timestamps.reduce(
        (acc, time, index) => ({
          ...acc,
          [time]: { rank: rankingArray[index], total: total[index] },
        }),
        {},
      );
      return {
        ...result,
        [network]: {
          ranking,
          ...other,
        },
      };
    }, {});
    return { ...state, [filtersToKey(filters)]: formattedRankings };
  }
  return state;
}
