import get from "lodash/get";
import { ActionType } from "redux-promise-middleware";

import {
  CREATE_AUDIENCE,
  CREATE_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  RECEIVE_CAMPAIGN_AUDIENCES,
  RECEIVE_CAMPAIGN_LOGS,
  RECEIVE_CAMPAIGN_STATS,
  RECEIVE_LOGS_STATS,
  REQUEST_CAMPAIGN_AUDIENCES,
  eventTypesMap,
} from "../actions";
import { DELETE_AUDIENCE, RECEIVE_AUDIENCE } from "../actions/audience";
import { RECEIVE_LOCATION_CAMPAIGN, REQUEST_LOCATION_CAMPAIGN } from "../actions/locations";

export function campaigns(state = {}, action) {
  switch (action.type) {
    case `${CREATE_CAMPAIGN}_${ActionType.Pending}`:
      return {
        ...state,
        isLoading: true,
      };
    case `${CREATE_CAMPAIGN}_${ActionType.Rejected}`:
      return {
        isLoading: false,
        [action.payload.id]: { isRejected: true, error: action.payload },
      };
    case RECEIVE_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        [action.campaign.id]: action.campaign,
      };
    default:
      return state;
  }
}

export function statsByCampaignId(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CAMPAIGN_STATS:
      return {
        ...state,
        [action.campaignId]: {
          ...state[action.campaignId],
          ...action.eventTypes.reduce(
            (obj, type) => ({
              ...obj,
              [eventTypesMap[type]]: get(action.meta, type, 0),
            }),
            {},
          ),
        },
      };
    case RECEIVE_CAMPAIGN_LOGS:
      return {
        ...state,
        [action.campaignId]: {
          ...state[action.campaignId],
          ...{ attributes: { ...action.data } },
        },
      };
    case RECEIVE_LOGS_STATS:
      return {
        ...state,
        [action.campaignId]: {
          ...state[action.campaignId],
          ...{
            eventLogsById: {
              ...state[action.campaignId].eventLogsById,
              [action.messageId]: [...action.data.data],
            },
          },
        },
      };
    default:
      return state;
  }
}

export function locationCampaigns(
  state = {
    isFetching: false,
    didInvalidate: false,
    linkage: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_LOCATION_CAMPAIGN:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_LOCATION_CAMPAIGN:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        linkage: {
          ...state.linkage,
          [action.linkage.id]: action.linkage,
        },
      };
    default:
      return state;
  }
}

export function audiences(state = {}, action) {
  switch (action.type) {
    case `${CREATE_AUDIENCE}_${ActionType.Fulfilled}`:
      return {
        [action.payload.id]: { isFulfilled: true, ...action.payload },
      };
    case `${CREATE_AUDIENCE}_${ActionType.Rejected}`:
      return {
        [action.payload.id]: { isRejected: true, error: action.payload },
      };
    case RECEIVE_AUDIENCE:
      return {
        ...state,
        [action.audience.id]: action.audience,
      };
    case DELETE_AUDIENCE:
      return Object.keys(state)
        .filter((key) => +key !== action.id)
        .reduce((obj, key) => ({ ...obj, [key]: state[key] }), {});
    default:
      return state;
  }
}

function campaignAudiences(
  state = {
    isFetching: false,
    didInvalidate: false,
    audiences: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CAMPAIGN_AUDIENCES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_CAMPAIGN_AUDIENCES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        audiences: action.audiences,
      };
    default:
      return state;
  }
}

export function audiencesByCampaignId(state = {}, action) {
  switch (action.type) {
    case REQUEST_CAMPAIGN_AUDIENCES:
    case RECEIVE_CAMPAIGN_AUDIENCES:
      return {
        ...state,
        [action.campaignId]: campaignAudiences(state[action.campaignId], action),
      };
    default:
      return state;
  }
}
