import { ActionType } from "redux-promise-middleware";

import {
  ASSIGN_LOCATION_DEVICE,
  CLEAR_DELETE_LOCATION_ERROR,
  CREATE_ACCOUNT_LOCATION,
  DELETE_LOCATION,
  EXECUTE_DEVICE_DEBUG,
  FETCH_ALL_VENUES,
  FETCH_LOCATION_DEVICES,
  FETCH_VENUE,
  FETCH_WIFI,
  REMOVE_LOCATION_DEVICE,
  UPDATE_DEVICE_SETTINGS,
  UPDATE_NETWORK_ACCESS,
  UPDATE_VENUE,
} from "../actions/venues";

export function venues(state = {}, action) {
  let venueData = {};
  switch (action.type) {
    case `${UPDATE_VENUE}_${ActionType.Pending}`:
    case `${FETCH_VENUE}_${ActionType.Pending}`:
    case `${FETCH_ALL_VENUES}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case `${UPDATE_VENUE}_${ActionType.Rejected}`:
    case `${FETCH_VENUE}_${ActionType.Rejected}`:
    case `${FETCH_ALL_VENUES}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.messageData.errors[0][0],
      };
    case `${UPDATE_VENUE}_${ActionType.Fulfilled}`:
    case `${FETCH_VENUE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
        errors: null,
      };
    case `${FETCH_ALL_VENUES}_${ActionType.Fulfilled}`:
      action.payload.data.forEach((el) => {
        venueData = { ...venueData, ...{ [el.id]: el } };
      });
      return {
        ...state,
        isFetching: false,
        data: venueData,
        meta: action.payload.meta,
        errors: null,
      };
    case `${DELETE_LOCATION}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.locationId]: {
          isDeletingVenue: true,
          deleteLocationError: null,
        },
      };
    case `${DELETE_LOCATION}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.locationId]: {
          isDeletingVenue: false,
          deleteLocationError: action.payload?.messageData?.errors[0]?.message,
        },
      };
    case CLEAR_DELETE_LOCATION_ERROR:
    case `${DELETE_LOCATION}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.locationId]: {
          isDeletingVenue: false,
          deleteLocationError: null,
        },
      };
    default:
      return state;
  }
}

export function locations(state = {}, action) {
  switch (action.type) {
    case `${CREATE_ACCOUNT_LOCATION}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isCreatingNewLocation: true,
          errors: null,
        },
      };
    case `${CREATE_ACCOUNT_LOCATION}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isCreatingNewLocation: false,
          errors: action.payload?.messageData?.errors,
        },
      };
    case `${CREATE_ACCOUNT_LOCATION}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          data: action.payload,
          isCreatingNewLocation: false,
          errors: null,
        },
      };
    default:
      return state;
  }
}
export function wifi(state = {}, action) {
  switch (action.type) {
    case `${UPDATE_NETWORK_ACCESS}_${ActionType.Pending}`:
    case `${FETCH_WIFI}_${ActionType.Pending}`:
      return {
        ...state,
        errors: null,
        isFetching: true,
      };
    case `${UPDATE_NETWORK_ACCESS}_${ActionType.Rejected}`:
    case `${FETCH_WIFI}_${ActionType.Rejected}`:
      return {
        ...state,
        errors: action.payload.messageData.errors[0],
        isFetching: false,
      };
    case `${UPDATE_NETWORK_ACCESS}_${ActionType.Fulfilled}`:
    case `${FETCH_WIFI}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        errors: null,
        data: {
          ...state.data,
          [action.payload.location_id]: action.payload,
        },
      };
    default:
      return state;
  }
}

export function devices(state = {}, action) {
  switch (action.type) {
    case `${FETCH_LOCATION_DEVICES}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        data: {
          ...state.data,
          [action.meta.locationId]: {
            isFetchingDevice: true,
          },
        },
      };
    case `${FETCH_LOCATION_DEVICES}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.meta.locationId]: {
            isFetchingDevice: false,
            errors: action.payload?.messageData.errors,
          },
        },
      };
    case `${FETCH_LOCATION_DEVICES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.meta.locationId]: {
            isFetchingDevice: false,
            devices: action.payload,
          },
        },
      };
    case `${ASSIGN_LOCATION_DEVICE}_${ActionType.Pending}`:
      return {
        ...state,
        isAssigning: true,
        errors: null,
      };
    case `${ASSIGN_LOCATION_DEVICE}_${ActionType.Rejected}`:
      return {
        ...state,
        isAssigning: false,
        isAssigned: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${ASSIGN_LOCATION_DEVICE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isAssigning: false,
        isAssigned: true,
        errors: null,
        data: {
          ...state.data,
          [action.meta.locationId]: { devices: action.payload },
        },
      };
    case `${EXECUTE_DEVICE_DEBUG}_${ActionType.Pending}`:
      return {
        ...state,
        isExecuting: true,
      };
    case `${EXECUTE_DEVICE_DEBUG}_${ActionType.Rejected}`:
      return {
        ...state,
        isExecuting: false,
      };
    case `${EXECUTE_DEVICE_DEBUG}_${ActionType.Fulfilled}`: {
      const { location_id: locationId, device_id: deviceId } = action.meta;
      return {
        ...state,
        isExecuting: false,
        data: {
          ...state.data,
          [locationId]: {
            ...state.data[locationId],
            devices: state.data[locationId].devices.map((el) => {
              if (el.id !== deviceId) {
                return el;
              }
              return {
                ...el,
                online: action.payload.reachable,
                response: action.payload.response,
              };
            }),
          },
        },
      };
    }
    case `${UPDATE_DEVICE_SETTINGS}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.deviceId]: {
          isUpdating: true,
          isUpdated: false,
          errors: null,
        },
      };
    case `${UPDATE_DEVICE_SETTINGS}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.deviceId]: {
          isUpdating: false,
          isUpdated: false,
          errors: action.payload?.messageData?.errors,
        },
      };
    case `${UPDATE_DEVICE_SETTINGS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.deviceId]: {
          isUpdating: false,
          isUpdated: true,
          errors: null,
        },
      };
    case `${REMOVE_LOCATION_DEVICE}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.deviceId]: {
          isRemoving: true,
          isRemoved: false,
          removeErrors: null,
        },
      };
    case `${REMOVE_LOCATION_DEVICE}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.deviceId]: {
          isRemoving: false,
          isRemoved: false,
          removeErrors: action.payload?.messageData?.errors,
        },
      };
    case `${REMOVE_LOCATION_DEVICE}_${ActionType.Fulfilled}`: {
      const { locationId, deviceId } = action.meta;
      return {
        ...state,
        [action.meta.deviceId]: {
          isRemoving: false,
          isRemoved: true,
          removeErrors: null,
        },
        data: {
          [action.meta.locationId]: state.data[locationId].devices.filter(
            (device) => device.id !== deviceId && device.mac_address !== deviceId,
          ),
        },
      };
    }
    default:
      return state;
  }
}
