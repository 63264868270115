import uniqBy from "lodash/uniqBy";
import { ActionType } from "redux-promise-middleware";

import {
  CREATE_LOCATION,
  DELETE_COMPETITORS_SUCCESS,
  FETCH_LOCATION_ONBOARDING_INSIGHTS,
  LOCATION_EVENT_TRIGGER,
  RECEIVE_CUSTOMER_LOCATIONS,
  RECEIVE_LOCATION,
  RECEIVE_LOCATION_CAMPAIGNS_LINKAGE,
  RECEIVE_LOCATION_SUBSCRIPTIONS,
  RECEIVE_PROPERTY_HEALTH,
  RECEIVE_PROPERTY_INFO,
  RECEIVE_PROPERTY_SEARCH,
  REQUEST_LOCATION_CAMPAIGNS_LINKAGE,
  REQUEST_LOCATION_SUBSCRIPTIONS,
  REQUEST_PROPERTY_HEALTH,
  REQUEST_PROPERTY_INFO,
  REQUEST_PROPERTY_SEARCH,
} from "../actions";

export function locationsById(state = {}, action) {
  switch (action.type) {
    case CREATE_LOCATION:
    case RECEIVE_LOCATION:
      return {
        ...state,
        [action.location.id]: action.location,
      };
    case RECEIVE_CUSTOMER_LOCATIONS:
      return {
        ...state,
        ...action.customerLocations.reduce(
          (acc, location) => ({ ...acc, [location.id]: location }),
          {},
        ),
      };
    default:
      return state;
  }
}

function subscriptions(
  state = {
    isFetching: false,
    didInvalidate: false,
    subscriptions: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_LOCATION_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_LOCATION_SUBSCRIPTIONS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        subscriptions: action.subscriptions,
      };
    default:
      return state;
  }
}

export function locationSubscriptionsById(state = {}, action) {
  switch (action.type) {
    case REQUEST_LOCATION_SUBSCRIPTIONS:
    case RECEIVE_LOCATION_SUBSCRIPTIONS:
      return {
        ...state,
        [action.locationId]: subscriptions(state[action.locationId], action),
      };
    default:
      return state;
  }
}

function linkage(
  state = {
    isFetching: false,
    didInvalidate: false,
    linkage: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_LOCATION_CAMPAIGNS_LINKAGE:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_LOCATION_CAMPAIGNS_LINKAGE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        linkage: uniqBy([...state.linkage, action.linkage], "id"),
      };
    default:
      return state;
  }
}

export function locationCampaignLinkageById(state = {}, action) {
  switch (action.type) {
    case REQUEST_LOCATION_CAMPAIGNS_LINKAGE:
    case RECEIVE_LOCATION_CAMPAIGNS_LINKAGE:
      return {
        ...state,
        [action.locationId]: linkage(state[action.locationId], action),
      };
    default:
      return state;
  }
}

export function properties(state = {}, { type, property, propertyId, health, competitorId }) {
  switch (type) {
    case REQUEST_PROPERTY_INFO:
      return {
        ...state,
        [propertyId]: { ...state[propertyId], isFetching: true },
      };
    case RECEIVE_PROPERTY_INFO:
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          id: propertyId,
          ...property.property,
          competitors: property.competitors,
          isFetching: false,
        },
      };
    case RECEIVE_PROPERTY_SEARCH:
      return property
        ? {
            ...state,
            [property.id]: { ...property },
          }
        : state;
    case DELETE_COMPETITORS_SUCCESS:
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          competitors: Object.keys(state[propertyId].competitors).reduce(
            (obj, key) =>
              competitorId === key ? obj : { ...obj, [key]: state[propertyId].competitors[key] },
            {},
          ),
        },
      };
    case REQUEST_PROPERTY_HEALTH:
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          health: { isFetching: true },
        },
      };
    case RECEIVE_PROPERTY_HEALTH:
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          health: {
            ...health,
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
}

export function propertySearch(state = { foundId: null }, action) {
  switch (action.type) {
    case REQUEST_PROPERTY_SEARCH:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PROPERTY_SEARCH:
      return {
        ...state,
        isFetching: false,
        foundId: action.property ? action.property.id : null,
      };
    default:
      return state;
  }
}

export function onboardingInsights(state = {}, action) {
  switch (action.type) {
    case `${FETCH_LOCATION_ONBOARDING_INSIGHTS}_${ActionType.Rejected}`:
      return {
        ...state,
        isError: true,
      };
    case `${FETCH_LOCATION_ONBOARDING_INSIGHTS}_${ActionType.Pending}`:
      return {
        ...state,
        isError: false,
        isFetching: true,
      };
    case `${FETCH_LOCATION_ONBOARDING_INSIGHTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isError: false,
        isFetching: false,
        insights: {
          ...state.insights,
          [action.meta.locationId]: action.payload,
        },
      };
    default:
      return state;
  }
}

export function locationEvents(state = {}, action) {
  switch (action.type) {
    case `${LOCATION_EVENT_TRIGGER}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.locationId]: {
          ...state[action.meta.locationId],
          [action.meta.event]: {
            isFetching: false,
            errors: action.error,
            triggered: false,
          },
        },
      };
    case `${LOCATION_EVENT_TRIGGER}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.locationId]: {
          ...state[action.meta.locationId],
          [action.meta.event]: {
            isFetching: true,
            errors: null,
            triggered: false,
          },
        },
      };
    case `${LOCATION_EVENT_TRIGGER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.locationId]: {
          ...state[action.meta.locationId],
          [action.meta.event]: {
            isFetching: false,
            triggered: true,
          },
        },
      };
    default:
      return state;
  }
}
