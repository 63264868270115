import omitBy from "lodash/omitBy";
import { stringify } from "query-string";

import productName from "utils/products";

import fetch, { getHost } from "./fetch";

export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const FETCH_ALL_ACCOUNTS = "FETCH_ALL_ACCOUNTS";
export const FETCH_ACCOUNT_RESOURCES = "FETCH_ACCOUNT_RESOURCES";
export const FETCH_ACCOUNT_LOCATION_RESOURCES = "FETCH_ACCOUNT_LOCATION_RESOURCES";
export const FETCH_ACCOUNT_WEBSITE_RESOURCES = "FETCH_ACCOUNT_WEBSITE_RESOURCES";
export const FETCH_ACCOUNT_USER = "FETCH_ACCOUNT_USER";
export const UPDATE_ACCOUNT_USER = "UPDATE_ACCOUNT_USER";
export const FETCH_ACCOUNT_USERS = "FETCH_ACCOUNT_USERS";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const ACCOUNT_CHECK = "ACCOUNT_CHECK";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const CREATE_ACCOUNT_USER = "CREATE_ACCOUNT_USER";
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const DELETE_ACCOUNT_USER = "DELETE_ACCOUNT_USER";
export const MAKE_ACCOUNT_USER_PRIMARY = "MAKE_ACCOUNT_USER_PRIMARY";
export const ADD_ACCOUNT_PRODUCT = "ADD_ACCOUNT_PRODUCT";
export const FETCH_ACCOUNT_TEMPLATES = "FETCH_ACCOUNT_TEMPLATES";
export const FETCH_ACCOUNT_LOCATIONS = "FETCH_ACCOUNT_LOCATIONS";
export const REMOVE_PRODUCT_ERRORS = "REMOVE_PRODUCT_ERRORS";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const REMOVE_ACCOUNT_ERRORS = "REMOVE_ACCOUNT_ERRORS";

export function fetchAccount(accountId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}`).then((res) => res.json()),
    });
}

export function fetchAllAccounts(params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  return (dispatch) =>
    dispatch({
      type: FETCH_ALL_ACCOUNTS,
      payload: fetch(`${getHost()}/accounts?${urlParams}`).then((res) => res.json()),
    });
}

export function fetchAllUsers(params, accountId) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));
  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT_USERS,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users?${urlParams}`).then((res) =>
        res.json(),
      ),
    });
}

export function createAccount(data) {
  return (dispatch) =>
    dispatch({
      type: CREATE_ACCOUNT,
      payload: fetch(`${getHost()}/accounts`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function accountCheck({ name, placeId }) {
  return (dispatch) =>
    dispatch({
      type: ACCOUNT_CHECK,
      payload: fetch(`${getHost()}/accounts/check?name=${name}&place_id=${placeId}`).then((res) =>
        res.json(),
      ),
    });
}

export function updateAccount({ accountId, data }) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: fetch(`${getHost()}/accounts/${accountId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function fetchAccountUser(accountId, userId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT_USER,
      meta: { accountId, userId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users/${userId}`).then((res) =>
        res.json(),
      ),
    });
}

export function updateAccountUser(accountId, userId, body) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_ACCOUNT_USER,
      meta: { accountId, userId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users/${userId}`, {
        method: "PATCH",
        body: JSON.stringify(body),
      }).then((res) => res.json()),
    });
}

export function createAccountUser(accountId, body) {
  return (dispatch) =>
    dispatch({
      type: CREATE_ACCOUNT_USER,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users`, {
        method: "POST",
        body: JSON.stringify(body),
      }).then((res) => res.json()),
    });
}

export function sendResetPassword(accountId, userId) {
  return (dispatch) =>
    dispatch({
      type: SEND_RESET_PASSWORD,
      meta: { accountId, userId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users/${userId}/reset-password`, {
        method: "POST",
      }).then((res) => res.json()),
    });
}

export function deleteAccountUser(accountId, userId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_ACCOUNT_USER,
      meta: { accountId, userId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users/${userId}`, {
        method: "DELETE",
      }).then((res) => res.json()),
    });
}

export function makeAccountUserPrimary(accountId, userId) {
  return (dispatch) =>
    dispatch({
      type: MAKE_ACCOUNT_USER_PRIMARY,
      meta: { accountId, userId },
      payload: fetch(`${getHost()}/accounts/${accountId}/users/${userId}/primary`, {
        method: "POST",
      }).then((res) => res.json()),
    });
}

export function addAccountProduct(accountId, data) {
  return (dispatch) =>
    dispatch({
      type: ADD_ACCOUNT_PRODUCT,
      payload: fetch(`${getHost()}/accounts/${accountId}/subscriptions`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then((res) => res.json()),
    });
}

export function fetchAccountTemplates(accountId) {
  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT_TEMPLATES,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}/templates`).then((res) => res.json()),
    });
}

export function fetchAccountLocations(accountId, params = {}) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[account]": accountId,
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));

  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT_LOCATIONS,
      meta: { accountId },
      payload: fetch(`${getHost()}/locations?${urlParams}`).then((res) => res.json()),
    });
}

export function removeProductErrors() {
  return (dispatch) => dispatch({ type: REMOVE_PRODUCT_ERRORS });
}

export function removeAccountErrors() {
  return (dispatch) => dispatch({ type: REMOVE_ACCOUNT_ERRORS });
}

export function deleteAccount(accountId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_ACCOUNT,
      payload: fetch(`${getHost()}/accounts/${accountId}`, {
        method: "DELETE",
      }).then((res) => res.json()),
    });
}
