import omitBy from "lodash/omitBy";
import { stringify } from "query-string";

import fetch, { getHost } from "./fetch";

export const FETCH_WEBSITES = "FETCH_WEBSITES";
export const ADD_WEBSITE = "ADD_WEBSITE";
export const DELETE_WEBSITE = "DELETE_WEBSITE";
export const FETCH_WEBSITE_STATUSES = "FETCH_WEBSITE_STATUSES";
export const FETCH_WEBSITE_ONBOARDING_INSIGHTS = "FETCH_WEBSITE_ONBOARDING_INSIGHTS";

export function fetchWebsites(params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));

  return (dispatch) =>
    dispatch({
      type: FETCH_WEBSITES,
      payload: fetch(`${getHost()}/websiteoptimizer/sites?${urlParams}`).then((res) => res.json()),
    });
}

export function deleteWebsite(siteId) {
  return (dispatch) =>
    dispatch({
      type: DELETE_WEBSITE,
      meta: {
        id: siteId,
      },
      payload: fetch(`${getHost()}/websiteoptimizer/sites/${siteId}`, {
        method: "DELETE",
        body: JSON.stringify({ websiteId: siteId }),
      }).then((res) => res.json()),
    });
}

export function fetchWebsiteStatuses(siteIds = []) {
  return (dispatch) =>
    dispatch({
      type: FETCH_WEBSITE_STATUSES,
      meta: { ids: siteIds },
      payload: fetch(
        `${getHost()}/websiteoptimizer/sites/status?filter[id]=${siteIds.join(",")}`,
      ).then((res) => res.json()),
    });
}
