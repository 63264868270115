import get from "lodash/get";

import { capitalizeFirstLetter } from "./string";

const channelsConfig = {
  tripadvisor: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
    pretty_name: "TripAdvisor",
    public: true,
  },
  facebook: {
    max_rating: 5,
    channel_type: ["review", "social"],
    channel_visibility: ["social"],
    review_url_template: "https://www.facebook.com/{id}/reviews/",
    public: true,
  },
  google: {
    max_rating: 5,
    channel_type: ["review", "social"],
    channel_visibility: ["social"],
    public: true,
  },
  thefork: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  foursquare: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
    public: true,
  },
  yelp: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
    review_url_template: "https://www.yelp.com/writeareview/biz/",
    public: true,
  },
  booking: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  twitter: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: ["social"],
  },
  zomato: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
    public: true,
  },
  hostelbookers: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  hostelworld: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  budgetplaces: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  opentable: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  bookatable: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  viator: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  justeat: {
    max_rating: 6,
    channel_type: ["review"],
    channel_visibility: ["review"],
    url: "just-eat.com",
  },
  expedia: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  agoda: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  zoover: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
    public: true,
  },
  airbnb: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  instagram: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: ["social"],
  },
  menulog: {
    max_rating: 6,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  deliveroo: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  resengo: {
    max_rating: 10,
    order: 130,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  truelocal: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
    public: true,
  },
  thuisbezorgd: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  takeaway: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
    pretty_name: "Takeaway.com",
  },
  ubereats: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  dimmi: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  pinterest: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: ["social"],
  },
  youtube: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: ["social"],
  },
  vimeo: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: [],
  },
  holidaycheck: {
    max_rating: 6,
    channel_type: ["review"],
    channel_visibility: ["review"],
    public: true,
  },
  tripadvisorforum: {
    max_rating: null,
    channel_type: ["social"],
    channel_visibility: [],
  },
  hotels: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  "2spaghi": {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: [],
  },
  musement: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  getyourguide: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  hotelclub: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  hostelsclub: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  laterooms: {
    max_rating: 6,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  hrs: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  wimdu: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  mrjet: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  cheaptickets: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  quandoo: {
    max_rating: 6,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  golocal: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  qualitando: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: [],
  },
  misiedo: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  ebookers: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  wotif: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  orbitz: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  homeaway: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  homelidays: {
    max_rating: null,
    channel_type: ["review"],
    channel_visibility: [],
  },
  hotwire: {
    max_rating: null,
    channel_type: ["review"],
    channel_visibility: [],
  },
  travelocity: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  qunar: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  eet: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
    pretty_name: "Eet.nu",
  },
  feedbackcompany: {
    max_rating: 10,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  trustpilot: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
  ctrip: {
    max_rating: 5,
    channel_type: ["review"],
    channel_visibility: ["review"],
  },
};

const configFor = (platform) => channelsConfig[platform.toLowerCase()];

export const allChannels = Object.keys(channelsConfig);
export const maxRating = (platform) => get(configFor(platform), "max_rating", null);
export const isPublic = (platform) => get(configFor(platform), "public", false);
export const prettyName = (platform) =>
  get(configFor(platform), "pretty_name", capitalizeFirstLetter(platform));
export const isReviewNetwork = (platform) =>
  get(configFor(platform), "channel_type", []).includes("review");
export const findChannelByUrl = (url) =>
  allChannels.find((channel) => {
    let key = channel;
    if (channelsConfig[channel].url) {
      key = channelsConfig[channel].url.replace(/\.\w+\/?/g, "");
    }
    return url && url.indexOf(key) >= 0;
  });

export default channelsConfig;
