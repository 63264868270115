import fetch, { getHost } from "./fetch";

export const RECEIVE_RANKINGS = "RECEIVE_RANKINGS";

const rankingsUrl = () => `${getHost()}/reviews/channelsRanking`;

export function receiveRankings(rankings, filters) {
  return {
    type: RECEIVE_RANKINGS,
    rankings,
    filters,
  };
}

export function findRankings(filters) {
  const { timeRange, monitorId, networks } = filters;
  const formattedFilters = {
    to: timeRange.endDate,
    from: timeRange.startDate,
    monitor_ids: [monitorId],
    networks,
  };
  return (dispatch) =>
    fetch(rankingsUrl(), {
      method: "POST",
      body: JSON.stringify({ filters: formattedFilters }),
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveRankings(json, filters)));
}

export function findRankingsIfNeeded(filters) {
  return (dispatch, getState) => {
    const {
      rankingsByFilters,
      selectedFilters: { key },
    } = getState();
    if (rankingsByFilters[key]) {
      return Promise.resolve();
    }
    return dispatch(findRankings(filters));
  };
}
