import { ActionType } from "redux-promise-middleware";

import {
  ACCOUNT_CHECK,
  ADD_ACCOUNT_PRODUCT,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_USER,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_USER,
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_LOCATIONS,
  FETCH_ACCOUNT_LOCATION_RESOURCES,
  FETCH_ACCOUNT_RESOURCES,
  FETCH_ACCOUNT_TEMPLATES,
  FETCH_ACCOUNT_USER,
  FETCH_ACCOUNT_USERS,
  FETCH_ACCOUNT_WEBSITE_RESOURCES,
  FETCH_ALL_ACCOUNTS,
  MAKE_ACCOUNT_USER_PRIMARY,
  REMOVE_ACCOUNT_ERRORS,
  REMOVE_PRODUCT_ERRORS,
  SEND_RESET_PASSWORD,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_USER,
} from "../actions";

function accounts(state = {}, action) {
  switch (action.type) {
    case `${FETCH_ACCOUNT}_${ActionType.Pending}`:
      return {
        ...state,
        current: {
          account: null,
          isFetching: true,
          errors: null,
        },
      };
    case `${FETCH_ACCOUNT}_${ActionType.Rejected}`:
      return {
        ...state,
        current: {
          account: null,
          isFetching: false,
          errors: action.errors,
        },
      };
    case `${FETCH_ACCOUNT}_${ActionType.Fulfilled}`:
      return {
        ...state,
        current: {
          isFetching: false,
          account: action.payload,
          errors: null,
        },
      };

    case `${FETCH_ALL_ACCOUNTS}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case `${FETCH_ALL_ACCOUNTS}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case `${FETCH_ALL_ACCOUNTS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        meta: action.payload.meta,
        errors: null,
      };

    case `${FETCH_ACCOUNT_USERS}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_USERS}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            isFetching: false,
            errors: action.payload.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_USERS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            isFetching: false,
            data: action.payload.data,
            meta: action.payload.meta,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              isFetching: true,
              errors: null,
            },
          },
        },
      };
    case `${FETCH_ACCOUNT_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              isFetching: false,
              errors: action.payload.errors,
            },
          },
        },
      };
    case `${FETCH_ACCOUNT_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              isFetching: false,
              errors: null,
              data: action.payload,
            },
          },
        },
      };
    case `${UPDATE_ACCOUNT_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.accountId][action.meta.userId],
              isUpdating: true,
              errors: null,
            },
          },
        },
      };
    case `${UPDATE_ACCOUNT_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.accountId]?.[action.meta.userId],
              isUpdating: false,
              errors: action.payload.messageData.errors,
            },
          },
        },
      };
    case `${UPDATE_ACCOUNT_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              data: action.payload,
              isUpdating: false,
              errors: null,
            },
          },
        },
      };
    case `${CREATE_ACCOUNT_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isCreatingUser: true,
            createUserErrors: null,
          },
        },
      };
    case `${CREATE_ACCOUNT_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isCreatingUser: false,
            createUserErrors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${CREATE_ACCOUNT_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            data: [...state.users?.[action.meta.accountId].data, action.payload],
            isCreatingUser: false,
            createUserErrors: null,
          },
        },
      };
    case `${SEND_RESET_PASSWORD}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.accountId]?.[action.meta.userId],
              isResettingPassword: true,
              resetPasswordSent: false,
              resetPasswordErrors: null,
            },
          },
        },
      };
    case `${SEND_RESET_PASSWORD}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.accountId]?.[action.meta.userId],
              isResettingPassword: false,
              resetPasswordSent: false,
              resetPasswordErrors: action.payload.messageData.errors,
            },
          },
        },
      };
    case `${SEND_RESET_PASSWORD}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.accountId]?.[action.meta.userId],
              isResettingPassword: false,
              resetPasswordErrors: null,
              resetPasswordSent: true,
            },
          },
        },
      };

    case `${FETCH_ACCOUNT_RESOURCES}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          resources: {
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_RESOURCES}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          resources: {
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_RESOURCES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state[action.meta.accountId],
          resources: {
            meta: action.payload.meta,
            data: action.payload.data,
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATION_RESOURCES}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          locationResources: {
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATION_RESOURCES}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          locationResources: {
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATION_RESOURCES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state[action.meta.accountId],
          locationResources: {
            meta: action.payload.meta,
            data: action.payload.data,
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_WEBSITE_RESOURCES}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          websiteResources: {
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_WEBSITE_RESOURCES}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          websiteResources: {
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_WEBSITE_RESOURCES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state[action.meta.accountId],
          websiteResources: {
            meta: action.payload.meta,
            data: action.payload.data,
            isFetching: false,
            errors: action.payload?.errors,
          },
        },
      };
    case `${ACCOUNT_CHECK}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case `${ACCOUNT_CHECK}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${ACCOUNT_CHECK}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        matchedAccounts: action.payload,
        errors: null,
      };

    case `${CREATE_ACCOUNT}_${ActionType.Pending}`:
      return {
        ...state,
        isCreatingAccount: true,
        errors: null,
      };
    case `${CREATE_ACCOUNT}_${ActionType.Rejected}`:
      return {
        ...state,
        isCreatingAccount: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${CREATE_ACCOUNT}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isCreatingAccount: false,
        errors: null,
      };

    case `${UPDATE_ACCOUNT}_${ActionType.Pending}`:
      return {
        ...state,
        isUpdating: true,
        errors: null,
      };
    case `${UPDATE_ACCOUNT}_${ActionType.Rejected}`:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${UPDATE_ACCOUNT}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isUpdating: false,
        errors: null,
      };
    case `${DELETE_ACCOUNT_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isDeleting: true,
            errors: null,
          },
        },
      };
    case `${DELETE_ACCOUNT_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isDeleting: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${DELETE_ACCOUNT_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isDeleting: false,
            errors: null,
          },
        },
      };
    case `${MAKE_ACCOUNT_USER_PRIMARY}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isMakingPrimary: true,
            errors: null,
          },
        },
      };
    case `${MAKE_ACCOUNT_USER_PRIMARY}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            isMakingPrimary: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${MAKE_ACCOUNT_USER_PRIMARY}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.accountId]: {
            ...state.users?.[action.meta.accountId],
            data: state.users?.[action.meta.accountId]?.data.map((user) => {
              if (user.id === action.meta.userId) {
                return { ...user, is_primary: true };
              }
              return { ...user, is_primary: false };
            }),
            isMakingPrimary: false,
            errors: null,
          },
        },
      };
    case `${ADD_ACCOUNT_PRODUCT}_${ActionType.Pending}`:
      return {
        ...state,
        isAddingProduct: true,
        errors: null,
      };
    case `${ADD_ACCOUNT_PRODUCT}_${ActionType.Rejected}`:
      return {
        ...state,
        isAddingProduct: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${ADD_ACCOUNT_PRODUCT}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isAddingProduct: false,
        errors: null,
      };
    case `${FETCH_ACCOUNT_TEMPLATES}_${ActionType.Pending}`:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.meta.accountId]: {
            ...state.templates?.[action.meta.accountId],
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_TEMPLATES}_${ActionType.Rejected}`:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.meta.accountId]: {
            ...state.templates?.[action.meta.accountId],
            isFetching: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_TEMPLATES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.meta.accountId]: {
            ...state.templates?.[action.meta.accountId],
            data: action.payload.data,
            isFetching: false,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATIONS}_${ActionType.Pending}`:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.meta.accountId]: {
            ...state.locations?.[action.meta.accountId],
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATIONS}_${ActionType.Rejected}`:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.meta.accountId]: {
            ...state.locations?.[action.meta.accountId],
            isFetching: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${FETCH_ACCOUNT_LOCATIONS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.meta.accountId]: {
            ...state.locations?.[action.meta.accountId],
            data: action.payload.data,
            isFetching: false,
            errors: null,
          },
        },
      };
    case `${DELETE_ACCOUNT}_${ActionType.Pending}`:
      return {
        ...state,
        isDeletingAccount: true,
      };
    case `${DELETE_ACCOUNT}_${ActionType.Rejected}`:
      return {
        ...state,
        isDeletingAccount: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${DELETE_ACCOUNT}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isDeletingAccount: false,
      };
    case REMOVE_PRODUCT_ERRORS:
      return { ...state, errors: null };
    case REMOVE_ACCOUNT_ERRORS:
      return { ...state, errors: null };
    default:
      return state;
  }
}

export default accounts;
