import { ActionType } from "redux-promise-middleware";

import { DELETE_ACCOUNT_FILE, FETCH_ACCOUNT_FILES, UPLOAD_ACCOUNT_FILE } from "../actions";

function files(state = {}, action) {
  switch (action.type) {
    case `${FETCH_ACCOUNT_FILES}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          isFetching: true,
          errors: null,
        },
      };
    case `${FETCH_ACCOUNT_FILES}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          isFetching: false,
          errors: action.payload?.messageData.errors,
        },
      };
    case `${FETCH_ACCOUNT_FILES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          isFetching: false,
          data: action.payload.data,
          meta: action.payload.meta,
          errors: null,
        },
      };
    case `${UPLOAD_ACCOUNT_FILE}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isUploading: true,
          errors: null,
        },
      };
    case `${UPLOAD_ACCOUNT_FILE}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isUploading: false,
          errors: action.payload?.messageData.errors,
        },
      };
    case `${UPLOAD_ACCOUNT_FILE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isUploading: false,
          data: [action.payload, ...state?.[action.meta.accountId].data],
          errors: null,
        },
      };
    case `${DELETE_ACCOUNT_FILE}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isDeleting: true,
          errors: null,
        },
      };
    case `${DELETE_ACCOUNT_FILE}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isDeleting: false,
          errors: action.payload.errors,
        },
      };
    case `${DELETE_ACCOUNT_FILE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.accountId]: {
          ...state?.[action.meta.accountId],
          isDeleting: false,
          data: state?.[action.meta.accountId].data.filter(
            (file) => file.id !== action.meta.fileId,
          ),
          errors: null,
        },
      };
    default:
      return state;
  }
}

export default files;
