import omitBy from "lodash/omitBy";
import { ActionType } from "redux-promise-middleware";

import {
  ADD_WEBSITE,
  DELETE_WEBSITE,
  FETCH_WEBSITES,
  FETCH_WEBSITE_STATUSES,
} from "../actions/webOptimizer";

export default function webOptimizer(state = {}, action) {
  switch (action.type) {
    case `${FETCH_WEBSITES}_${ActionType.Pending}`:
    case `${ADD_WEBSITE}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case `${FETCH_WEBSITES}_${ActionType.Rejected}`:
    case `${ADD_WEBSITE}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.messageData.errors,
      };
    case `${DELETE_WEBSITE}_${ActionType.Pending}`:
      return {
        ...state,
        [action.meta.id]: {
          isDeletingWebsite: true,
          errors: null,
        },
      };
    case `${DELETE_WEBSITE}_${ActionType.Rejected}`:
      return {
        ...state,
        [action.meta.id]: {
          isDeletingWebsite: false,
          errors: action.payload.messageData.errors?.[0].message,
        },
      };
    case `${DELETE_WEBSITE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        [action.meta.id]: {
          errors: null,
          isDeletingWebsite: false,
        },
        data: omitBy(state.data, ({ id }) => action.meta.id === id),
      };
    case `${ADD_WEBSITE}_${ActionType.Fulfilled}`:
      return {
        ...state,
        errors: null,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case `${FETCH_WEBSITES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data.reduce(
          (acc, current) => ({ ...acc, ...{ [current.id]: current } }),
          {},
        ),
        meta: action.payload.meta,
        errors: null,
      };
    case `${FETCH_WEBSITE_STATUSES}_${ActionType.Pending}`:
      return {
        ...state,
        statuses: action.meta.ids.reduce((acc, current) => {
          acc[current] = { isFetching: true };
          return acc;
        }, {}),
      };
    case `${FETCH_WEBSITE_STATUSES}_${ActionType.Rejected}`:
      return {
        ...state,
        statuses: action.meta.ids.reduce((acc, current) => {
          acc[current] = { isFetching: false, error: current.error || true };
          return acc;
        }, {}),
      };
    case `${FETCH_WEBSITE_STATUSES}_${ActionType.Fulfilled}`:
      return {
        ...state,
        statuses: action.payload.data.reduce((acc, current) => {
          acc[current.id] = { ...current, ...{ isFetching: false, error: undefined } };
          return acc;
        }, {}),
      };
    default:
      return state;
  }
}
