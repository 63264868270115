import fetch, { getHost } from "./fetch";

export const SELECT_FILTERS = "SELECT_FILTERS";
export const CREATE_SHORT_URL = "CREATE_SHORT_URL";

const urlShortenerUrl = () => `${getHost()}/url-shortener`;

export function selectFilters(filters) {
  return {
    type: SELECT_FILTERS,
    filters,
  };
}

export function shortenUrl(longUrl) {
  return {
    type: CREATE_SHORT_URL,
    payload: fetch(`${urlShortenerUrl()}/shorten`, {
      method: "POST",
      body: JSON.stringify({
        long_url: longUrl,
      }),
    }).then((res) => res.json()),
  };
}
