import fetch, { getHost } from "./fetch";

export const CREATE_AUDIENCE = "CREATE_AUDIENCE";
export const RECEIVE_AUDIENCE = "RECEIVE_AUDIENCE";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";

const audienceUrl = () => `${getHost()}/audiences`;

export function receiveAudience(audience) {
  return {
    type: RECEIVE_AUDIENCE,
    audience,
  };
}

export function removeAudience(id) {
  return {
    type: DELETE_AUDIENCE,
    id,
  };
}

export function createAudience(audience) {
  return {
    type: CREATE_AUDIENCE,
    payload: fetch(audienceUrl(), {
      method: "POST",
      body: JSON.stringify(audience),
    })
      .then((response) => response.headers.get("Location"))
      .then((location) => {
        const match = location.match(/\d+$/);
        if (!match) throw new Error("Could not get audience id");
        return match[0];
      }),
  };
}

export function deleteAudience(audienceId) {
  return (dispatch) =>
    fetch(`${audienceUrl()}/${audienceId}`, { method: "DELETE" }).then(() =>
      dispatch(removeAudience(audienceId)),
    );
}
