import { ActionType } from "redux-promise-middleware";

import {
  CREATE_PARTNER,
  CREATE_PARTNER_USER,
  DEACTIVATE_PARTNER_SSO_TOKEN,
  DELETE_PARTNER,
  DELETE_PARTNER_USER,
  FETCH_ALL_PARTNERS,
  FETCH_PARTNER,
  FETCH_PARTNERS_BY_ID,
  FETCH_PARTNERS_SENDER_EMAILS,
  FETCH_PARTNER_SSO_TOKEN,
  FETCH_PARTNER_USER,
  FETCH_PARTNER_USERS,
  GENERATE_PARTNER_SSO_TOKEN,
  MAKE_PARTNER_USER_PRIMARY,
  REMOVE_DELETE_PARTNER_ERROR,
  SEND_RESET_PASSWORD_PARTNER_USER,
  UPDATE_PARTNER,
  UPDATE_PARTNER_USER,
  REMOVE_CURRENT_PARTNER,
} from "../actions";

function partners(state = {}, action) {
  switch (action.type) {
    case REMOVE_CURRENT_PARTNER: {
      return {
        ...state,
        current: {
          partner: null,
          isFetching: false,
          errors: null,
        },
      };
    }
    case `${FETCH_PARTNER}_${ActionType.Pending}`:
      return {
        ...state,
        current: {
          partner: null,
          isFetching: true,
          errors: null,
        },
      };
    case `${FETCH_PARTNER}_${ActionType.Rejected}`:
      return {
        ...state,
        current: {
          partner: null,
          isFetching: false,
          errors: action.errors,
        },
      };
    case `${FETCH_PARTNER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        current: {
          isFetching: false,
          partner: action.payload,
          errors: null,
        },
      };
    case `${FETCH_PARTNER_SSO_TOKEN}_${ActionType.Pending}`:
      return {
        ...state,
        ssoToken: {
          ...state?.ssoToken,
          isFetching: true,
          errors: null,
        },
      };
    case `${FETCH_PARTNER_SSO_TOKEN}_${ActionType.Rejected}`:
      return {
        ...state,
        ssoToken: {
          ...state?.ssoToken,
          isFetching: false,
          errors: action.errors,
        },
      };
    case `${FETCH_PARTNER_SSO_TOKEN}_${ActionType.Fulfilled}`:
      return {
        ...state,
        ssoToken: {
          active: action.payload.active,
          token: action.payload.token,
          isFetching: false,
          errors: null,
        },
      };
    case `${GENERATE_PARTNER_SSO_TOKEN}_${ActionType.Pending}`:
    case `${DEACTIVATE_PARTNER_SSO_TOKEN}_${ActionType.Pending}`:
      return {
        ...state,
        ssoToken: {
          ...state?.ssoToken,
          isUpdating: true,
          errors: null,
        },
      };
    case `${GENERATE_PARTNER_SSO_TOKEN}_${ActionType.Rejected}`:
    case `${DEACTIVATE_PARTNER_SSO_TOKEN}_${ActionType.Rejected}`:
      return {
        ...state,
        ssoToken: {
          ...state?.ssoToken,
          isUpdating: false,
          errors: action.payload?.messageData?.errors,
        },
      };
    case `${GENERATE_PARTNER_SSO_TOKEN}_${ActionType.Fulfilled}`:
      return {
        ...state,
        ssoToken: {
          ...state?.ssoToken,
          active: true,
          token: action.payload.token,
          isUpdating: false,
          errors: null,
        },
      };
    case `${DEACTIVATE_PARTNER_SSO_TOKEN}_${ActionType.Fulfilled}`:
      return {
        ...state,
        ssoToken: {
          active: false,
          token: null,
          isUpdating: false,
          errors: null,
        },
      };
    case `${UPDATE_PARTNER}_${ActionType.Pending}`:
      return {
        ...state,
        isUpdating: true,
        errors: null,
      };
    case `${UPDATE_PARTNER}_${ActionType.Rejected}`:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${UPDATE_PARTNER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isUpdating: false,
        errors: null,
      };
    case `${CREATE_PARTNER}_${ActionType.Pending}`:
      return {
        ...state,
        isCreatingPartner: true,
        errors: null,
      };
    case `${CREATE_PARTNER}_${ActionType.Rejected}`:
      return {
        ...state,
        isCreatingPartner: false,
        errors: action.payload?.messageData?.errors,
      };
    case `${CREATE_PARTNER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isCreatingPartner: false,
        errors: null,
      };
    case `${FETCH_ALL_PARTNERS}_${ActionType.Pending}`:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case `${FETCH_ALL_PARTNERS}_${ActionType.Rejected}`:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.messageData.errors[0][0],
      };
    case `${FETCH_ALL_PARTNERS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        meta: action.payload.meta,
        errors: null,
      };
    case `${DELETE_PARTNER}_${ActionType.Pending}`:
      return {
        ...state,
        isDeleting: true,
        deletePartnerError: null,
      };
    case `${DELETE_PARTNER}_${ActionType.Rejected}`:
      return {
        ...state,
        isDeleting: false,
        deletePartnerError: action.payload.messageData.errors?.[0].message,
      };
    case `${DELETE_PARTNER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        isDeleting: false,
        deletePartnerError: null,
      };
    case `${FETCH_PARTNERS_BY_ID}_${ActionType.Pending}`:
      return {
        ...state,
        partnersById: {
          ...state.partnersById,
          isFetching: true,
          errors: null,
        },
      };
    case `${FETCH_PARTNERS_BY_ID}_${ActionType.Rejected}`:
      return {
        ...state,
        partnersById: {
          ...state.partnersById,
          isFetching: false,
          errors: action.payload,
        },
      };
    case `${FETCH_PARTNERS_BY_ID}_${ActionType.Fulfilled}`:
      return {
        ...state,
        partnersById: {
          ...state.partnersById,
          isFetching: false,
          errors: null,
          data: action.payload,
        },
      };
    case `${FETCH_PARTNER_USERS}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            isFetching: true,
            errors: null,
          },
        },
      };
    case `${FETCH_PARTNER_USERS}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            isFetching: false,
            errors: action.payload.errors,
          },
        },
      };
    case `${FETCH_PARTNER_USERS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            isFetching: false,
            data: action.payload.data,
            meta: action.payload.meta,
            errors: null,
          },
        },
      };
    case `${CREATE_PARTNER_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isCreatingUser: true,
            createUserErrors: null,
          },
        },
      };
    case `${CREATE_PARTNER_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isCreatingUser: false,
            createUserErrors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${CREATE_PARTNER_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            data: [...state.users?.[action.meta.partnerId].data, action.payload],
            isCreatingUser: false,
            createUserErrors: null,
          },
        },
      };
    case `${MAKE_PARTNER_USER_PRIMARY}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isMakingPrimary: true,
            errors: null,
          },
        },
      };
    case `${MAKE_PARTNER_USER_PRIMARY}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isMakingPrimary: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${MAKE_PARTNER_USER_PRIMARY}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            data: state.users?.[action.meta.partnerId]?.data.map((user) => {
              if (user.id === action.meta.userId) {
                return { ...user, is_primary: true };
              }
              return { ...user, is_primary: false };
            }),
            isMakingPrimary: false,
            errors: null,
          },
        },
      };
    case `${SEND_RESET_PASSWORD_PARTNER_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.partnerId]?.[action.meta.userId],
              isResettingPassword: true,
              resetPasswordSent: false,
              resetPasswordErrors: null,
            },
          },
        },
      };
    case `${SEND_RESET_PASSWORD_PARTNER_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.partnerId]?.[action.meta.userId],
              isResettingPassword: false,
              resetPasswordSent: false,
              resetPasswordErrors: action.payload.messageData.errors,
            },
          },
        },
      };
    case `${SEND_RESET_PASSWORD_PARTNER_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.partnerId]?.[action.meta.userId],
              isResettingPassword: false,
              resetPasswordErrors: null,
              resetPasswordSent: true,
            },
          },
        },
      };
    case `${DELETE_PARTNER_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isDeleting: true,
            errors: null,
          },
        },
      };
    case `${DELETE_PARTNER_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isDeleting: false,
            errors: action.payload?.messageData?.errors,
          },
        },
      };
    case `${DELETE_PARTNER_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            isDeleting: false,
            errors: null,
          },
        },
      };
    case `${FETCH_PARTNER_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              isFetching: true,
              errors: null,
            },
          },
        },
      };
    case `${FETCH_PARTNER_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              isFetching: false,
              errors: action.payload.errors,
            },
          },
        },
      };
    case `${FETCH_PARTNER_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              isFetching: false,
              errors: null,
              data: action.payload,
            },
          },
        },
      };
    case `${UPDATE_PARTNER_USER}_${ActionType.Pending}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.partnerId][action.meta.userId],
              isUpdatingUser: true,
              errors: null,
            },
          },
        },
      };
    case `${UPDATE_PARTNER_USER}_${ActionType.Rejected}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              ...state.users?.[action.meta.partnerId]?.[action.meta.userId],
              isUpdatingUser: false,
              errors: action.payload.messageData.errors,
            },
          },
        },
      };
    case `${UPDATE_PARTNER_USER}_${ActionType.Fulfilled}`:
      return {
        ...state,
        users: {
          ...state.users,
          [action.meta.partnerId]: {
            ...state.users?.[action.meta.partnerId],
            [action.meta.userId]: {
              data: action.payload,
              isUpdatingUser: false,
              errors: null,
            },
          },
        },
      };
    case REMOVE_DELETE_PARTNER_ERROR:
      return { ...state, deletePartnerError: null };
    case `${FETCH_PARTNERS_SENDER_EMAILS}_${ActionType.Pending}`:
      return {
        ...state,
        senderEmails: {
          ...state?.senderEmails,
          isFetching: true,
          isRejected: false,
        },
      };
    case `${FETCH_PARTNERS_SENDER_EMAILS}_${ActionType.Rejected}`:
      return {
        ...state,
        senderEmails: {
          ...state?.senderEmails,
          isFetching: false,
          isRejected: true,
        },
      };
    case `${FETCH_PARTNERS_SENDER_EMAILS}_${ActionType.Fulfilled}`:
      return {
        ...state,
        senderEmails: {
          data: action.payload.data,
          meta: action.payload.meta,
          isFetching: false,
          isRejected: false,
        },
      };
    default:
      return state;
  }
}

export default partners;
