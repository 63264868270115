import trackers, { EVENT_RM_ADD_COMPETITOR } from "../trackers";
import fetch, { getHost } from "./fetch";
import { addCompetitorProperty } from "./onboarding";

export const UPDATE_COMPETITORS_LOADING = "UPDATE_COMPETITORS_LOADING";
export const UPDATE_COMPETITORS_SUCCESS = "UPDATE_COMPETITORS_SUCCESS";
export const DELETE_COMPETITORS_SUCCESS = "DELETE_COMPETITORS_SUCCESS";
export const UPDATE_COMPETITORS_FAILED = "UPDATE_COMPETITORS_FAILED";

const reviewsUrl = () => `${getHost()}/reviews`;
const competitorUrl = () => `${reviewsUrl()}/competitor`;

function requestCompetitors(competitorId) {
  return {
    type: UPDATE_COMPETITORS_LOADING,
    competitorId,
  };
}

function updated(competitorId) {
  return {
    type: UPDATE_COMPETITORS_SUCCESS,
    competitorId,
  };
}

function deleted(propertyId, competitorId) {
  return {
    type: DELETE_COMPETITORS_SUCCESS,
    propertyId,
    competitorId,
  };
}

function failed(error) {
  return {
    type: UPDATE_COMPETITORS_FAILED,
    error,
  };
}

export function deleteCompetitor(propertyId, id, url = competitorUrl()) {
  return (dispatch) => {
    dispatch(requestCompetitors(id));
    return fetch(url, {
      method: "DELETE",
      body: JSON.stringify({
        id: propertyId,
        competitor_id: id,
      }),
    })
      .then((response) => response.json())
      .then(() => dispatch(updated(id)))
      .then(() => dispatch(deleted(propertyId, id)))
      .catch((error) => dispatch(failed(error.messageData.errors[0].message)));
  };
}

export function addCompetitorByProperty(property, locationId) {
  return (dispatch) => {
    dispatch(requestCompetitors());
    return dispatch(addCompetitorProperty(property, locationId))
      .then(() => dispatch(updated()))
      .then(() => trackers.track(EVENT_RM_ADD_COMPETITOR, { location_id: locationId }))
      .catch((error) => dispatch(failed(error.messageData.errors[0].message)));
  };
}
