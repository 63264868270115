import {
  RECEIVE_COMPETITORS,
  RECEIVE_COMPETITORS_RANK,
  RECEIVE_COMPETITORS_RATINGS,
  RECEIVE_COMPETITORS_STATS,
  REQUEST_COMPETITORS,
  REQUEST_COMPETITORS_RANK,
  REQUEST_COMPETITORS_RATINGS,
  REQUEST_COMPETITORS_STATS,
} from "../actions";
import { filtersToKey } from "../utils/reducerUtils";

function competitors(
  state = {
    isFetching: false,
    didInvalidate: false,
    competitors: [],
    details: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_COMPETITORS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_COMPETITORS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        competitors: action.competitors,
        details: action.details,
        lastUpdated: action.lastUpdated,
      };
    default:
      return state;
  }
}

function competitorsRatings(action) {
  switch (action.type) {
    case REQUEST_COMPETITORS_RATINGS:
      return {
        isFetching: true,
        didInvalidate: false,
        monitorIds: action.monitorIds,
      };
    case RECEIVE_COMPETITORS_RATINGS:
      return {
        isFetching: false,
        didInvalidate: false,
        monitorIds: action.monitorIds,
        ratings: action.ratings,
      };
    default:
      return {};
  }
}

function competitorsRanks(action) {
  switch (action.type) {
    case REQUEST_COMPETITORS_RANK:
      return {
        isFetching: true,
        didInvalidate: false,
        monitorIds: action.monitorIds,
      };
    case RECEIVE_COMPETITORS_RANK:
      return {
        isFetching: false,
        didInvalidate: false,
        monitorIds: action.monitorIds,
        rankings: action.rankings,
      };
    default:
      return {};
  }
}

function competitorsStats(action) {
  switch (action.type) {
    case REQUEST_COMPETITORS_STATS:
      return {
        isFetching: true,
        didInvalidate: false,
        monitorIds: action.monitorIds,
      };
    case RECEIVE_COMPETITORS_STATS:
      return {
        isFetching: false,
        didInvalidate: false,
        monitorIds: action.monitorIds,
        stats: action.stats,
      };
    default:
      return {};
  }
}

export function competitorsById(state = {}, action) {
  // const key = filtersToKey(action.filters);
  const key = action.monitorId;
  switch (action.type) {
    case REQUEST_COMPETITORS:
    case RECEIVE_COMPETITORS:
      return {
        ...state,
        [key]: competitors(state[action.monitorId], action),
      };
    default:
      return state;
  }
}

export function competitorsRatingsById(state = {}, action) {
  const key = filtersToKey(action.filters);
  switch (action.type) {
    case REQUEST_COMPETITORS_RATINGS:
    case RECEIVE_COMPETITORS_RATINGS:
      return {
        ...state,
        [key]: competitorsRatings(action),
      };
    default:
      return state;
  }
}

export function competitorsRanksById(state = {}, action) {
  const key = filtersToKey(action.filters);
  switch (action.type) {
    case REQUEST_COMPETITORS_RANK:
    case RECEIVE_COMPETITORS_RANK:
      return {
        ...state,
        [key]: competitorsRanks(action),
      };
    default:
      return state;
  }
}

export function competitorsStatsById(state = {}, action) {
  const key = filtersToKey(action.filters);
  switch (action.type) {
    case REQUEST_COMPETITORS_STATS:
    case RECEIVE_COMPETITORS_STATS:
      return {
        ...state,
        [key]: competitorsStats(action),
      };
    default:
      return state;
  }
}
