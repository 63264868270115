import omitBy from "lodash/omitBy";
import { stringify } from "query-string";

import fetch, { getHost } from "./fetch";

export const FETCH_ACCOUNT_FILES = "FETCH_ACCOUNT_FILES";
export const UPLOAD_ACCOUNT_FILE = "UPLOAD_ACCOUNT_FILE";
export const DELETE_ACCOUNT_FILE = "DELETE_ACCOUNT_FILE";

export function fetchAccountFiles(accountId, params) {
  const defaultParams = {
    page: 1,
    per_page: 10,
    sort: "-created_at",
    "filter[query]": "",
  };
  const urlParams = stringify(omitBy({ ...defaultParams, ...params }, (val) => val === ""));

  return (dispatch) =>
    dispatch({
      type: FETCH_ACCOUNT_FILES,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}/files?${urlParams}`).then((res) =>
        res.json(),
      ),
    });
}

export function uploadAccountFile({ accountId, formData }) {
  return (dispatch) =>
    dispatch({
      type: UPLOAD_ACCOUNT_FILE,
      meta: { accountId },
      payload: fetch(`${getHost()}/accounts/${accountId}/files`, {
        headers: {
          Accept: "application/json",
        },
        method: "POST",
        body: formData,
      }).then((res) => res.json()),
    });
}

export function deleteAccountFile({ accountId, fileId }) {
  return (dispatch) =>
    dispatch({
      type: DELETE_ACCOUNT_FILE,
      meta: { accountId, fileId },
      payload: fetch(`${getHost()}/accounts/${accountId}/files/${fileId}`, {
        method: "DELETE",
      }).then((res) => res.json()),
    });
}
