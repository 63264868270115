const filterNulls = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null && object[key] !== [null]) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};

export default filterNulls;
