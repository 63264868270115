import fetch, { getHost } from "./fetch";

const reviewsUrl = () => `${getHost()}/reviews`;
const clustersUrl = () => `${reviewsUrl()}/clustersInfo`;
const countriesUrl = () => `${reviewsUrl()}/availableCountries`;

export const REQUEST_REVIEW_CLUSTERS = "REQUEST_CLUSTERS";
export const RECEIVE_REVIEW_CLUSTERS = "RECEIVE_CLUSTERS";
export const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";

function requestClusters() {
  return {
    type: REQUEST_REVIEW_CLUSTERS,
  };
}

function receiveClusters(payload) {
  return {
    type: RECEIVE_REVIEW_CLUSTERS,
    clusters: payload.clusters,
  };
}

export function fetchClustersInfo(monitorIds) {
  return (dispatch) => {
    dispatch(requestClusters());
    return fetch(clustersUrl(), {
      method: "POST",
      body: JSON.stringify({ filters: { monitor_ids: monitorIds } }),
    })
      .then((response) => response.json())
      .then((payload) => dispatch(receiveClusters(payload)));
  };
}

function requestCountries() {
  return {
    type: REQUEST_COUNTRIES,
  };
}

function receiveCountries(countries) {
  return {
    type: RECEIVE_COUNTRIES,
    countries,
  };
}

export function fetchAvailableCountries() {
  return (dispatch, getState) => {
    const {
      configuration: { countries },
    } = getState();
    if (!countries) {
      dispatch(requestCountries());
      return fetch(countriesUrl())
        .then((response) => response.json())
        .then((payload) => dispatch(receiveCountries(payload)));
    }

    return Promise.resolve();
  };
}
