export const primaryChannels = [
  {
    name: "tripadvisor",
    url: "tripadvisor.com/",
  },
  {
    name: "facebook",
    url: "facebook.com/",
  },
  {
    name: "google",
    url: "google.com/",
  },
  {
    name: "thefork",
    url: "thefork.com/",
  },
  {
    name: "foursquare",
    url: "foursquare.com/",
  },
  {
    name: "yelp",
    url: "yelp.com/",
  },
  {
    name: "booking",
    url: "booking.com/",
  },
  {
    name: "twitter",
    url: "twitter.com/",
  },
  {
    name: "zomato",
    url: "zomato.com/",
  },
];

export const secondaryChannels = [
  {
    name: "ctrip",
    url: "trip.com/",
  },
  {
    name: "hostelworld",
    url: "hostelworld.com/",
  },
  {
    name: "hostelbookers",
    url: "hostelbookers.com/",
  },
  {
    name: "budgetplaces",
    url: "budgetplaces.com/",
  },
  {
    name: "opentable",
    url: "opentable.com/",
  },
  {
    name: "bookatable",
    url: "bookatable.com/",
  },
  {
    name: "viator",
    url: "viator.com/",
  },
  {
    name: "justeat",
    url: "just-eat.com/",
  },
  {
    name: "expedia",
    url: "expedia.com/",
  },
  {
    name: "agoda",
    url: "agoda.com/",
  },
  {
    name: "zoover",
    url: "zoover.com/",
  },
  {
    name: "instagram",
    url: "instagram.com/",
  },
  {
    name: "menulog",
    url: "menulog.com.au/",
  },
  {
    name: "deliveroo",
    url: "deliveroo.com/",
  },
  {
    name: "resengo",
    url: "resengo.com/",
  },
  {
    name: "dimmi",
    url: "dimmi.com.au/",
  },
  {
    name: "thuisbezorgd",
    url: "thuisbezorgd.nl/",
  },
  {
    name: "truelocal",
    url: "truelocal.com.au/",
  },
  {
    name: "ubereats",
    url: "ubereats.com/",
  },
  {
    name: "eet.nu",
    url: "eet.nu/",
  },
  {
    name: "feedbackcompany",
    url: "feedbackcompany.com/",
  },
  {
    name: "trustpilot",
    url: "trustpilot.com/",
  },
];

export const allChannels = primaryChannels.concat(secondaryChannels);
