import isArray from "lodash/isArray";
import pullAll from "lodash/pullAll";

import {
  RECEIVE_REVIEWS,
  RECEIVE_REVIEW_DETAILS,
  REMOVE_REVIEW_FILTERS,
  REQUEST_REVIEWS,
  REQUEST_REVIEW_DETAILS,
  SELECT_REVIEW_FILTERS,
} from "../actions";
import filtersReviewToKey from "../utils/reviewUtils";
import { createStatsReducer, createStatsReducerByFilters } from "./overview";

export function selectedReviewFilters(state = {}, action) {
  switch (action.type) {
    case SELECT_REVIEW_FILTERS:
      return {
        ...state,
        ...action.filters,
        key: filtersReviewToKey({ ...state, ...action.filters }),
      };
    case REMOVE_REVIEW_FILTERS: {
      const filtered = { ...state };
      const { ...removeFilters } = action.filter;
      Object.keys(removeFilters).forEach((filter) => {
        const current = filtered[filter];
        if (isArray(current) && action.filter[filter] !== "all") {
          pullAll(filtered[filter], action.filter[filter]);
        } else {
          delete filtered[filter];
        }
      });
      return {
        ...filtered,
        key: filtersReviewToKey({ ...filtered }),
      };
    }
    default:
      return state;
  }
}

function statsReview(state, action) {
  const requestAction = REQUEST_REVIEW_DETAILS;
  const receiveAction = RECEIVE_REVIEW_DETAILS;
  return createStatsReducer(state, action, requestAction, receiveAction, true);
}

const initialReviewStats = {
  initial: {
    stats: {
      contents: [],
      contentsID: [],
      trends: {},
      loading: true,
    },
    isFetching: true,
  },
};
export function statsReviewByFilters(state = initialReviewStats, action) {
  const request = REQUEST_REVIEW_DETAILS;
  const receive = RECEIVE_REVIEW_DETAILS;
  const reducer = statsReview;
  const initialState = initialReviewStats.initial;
  const keyMapper = filtersReviewToKey;
  const options = { keyMapper, initialState };
  return createStatsReducerByFilters(state, action, request, receive, reducer, options);
}

function reviews(state, action) {
  const request = REQUEST_REVIEWS;
  const receive = RECEIVE_REVIEWS;
  return createStatsReducer(state, action, request, receive);
}

const initialReviews = { initial: { stats: { contents: [], contentsID: [] } } };
export function reviewsByFilters(state = initialReviews, action) {
  const request = REQUEST_REVIEWS;
  const receive = RECEIVE_REVIEWS;
  const reducer = reviews;
  const keyMapper = filtersReviewToKey;
  const initialState = initialReviews.initial;
  const options = { keyMapper, initialState };
  return createStatsReducerByFilters(state, action, request, receive, reducer, options);
}
